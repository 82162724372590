import React, { useEffect, useState } from "react";
import Button from "../design/Button";
import GModal from "../design/components/GModal";
import musicFileIcon from "../../assets/music file icon.svg";
import { capitalizeString, formatFileSize } from "../../utility-functions";
import CloseIcon from "@mui/icons-material/Close";
import listenIcon from "../../assets/listen-icon-green.svg";
import editIcon from "../../assets/edit-icon.svg";
import { MenuItem, Select } from "@mui/material";
import { JService } from "../api-service/ApiService";
import EditOrderModal from "./EditOrderModal";
import { topNavAccountSelectedAccountTickIcon } from "../../utility-functions/assetLoader";
import commonStyles from "../design/commonStyles";
import { enqueueSnackbar } from 'notistack';
import { useSelector } from "react-redux";

const API_BASE_URL = process.env.REACT_APP_BETA;
function IncomingTab({ originalSettings, setOriginalSettings }) {

  const [workingHoursMsgType, setWorkingHoursMsgType] = useState("none");
  const [workingHoursMsgFile, setworkingHoursMsgFile] = useState([]);
  const [workingHoursMsgURL, setWorkingHoursMsgURL] = useState("");
  const [workingHoursFileChanged, setWorkingHoursFileChanged] = useState(false);

  const [connectToType, setConnectToType] = useState("user");
  const [connectToUser, setConnectToUser] = useState("");
  const [connectToTeam, setConnectToTeam] = useState("");
  const [callDistribution, setCallDistribution] = useState(1);
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);

  const [afterHoursMsgType, setAfterHoursMsgType] = useState("none");
  const [afterHoursMsgFile, setAfterHoursMsgFile] = useState([]);
  const [afterHoursMsgURL, setAfterHoursMsgURL] = useState("");
  const [afterHoursFileChanged, setAfterHoursFileChanged] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [audioModalURL, setaudioModalURL] = useState("");

  const reduxSubscription = useSelector(state=>state?.account?.value?.subscription)

  useEffect(() => {
    if (originalSettings.working_hours_message) {
      setWorkingHoursMsgURL(originalSettings.working_hours_message.url);
      setWorkingHoursMsgType("audio");
      setworkingHoursMsgFile(
        [].concat([{ name: originalSettings.working_hours_message.name }])
      );
    } else {
      setWorkingHoursMsgURL("");
      setWorkingHoursMsgType("none");
      setworkingHoursMsgFile([]);
    }

    if (originalSettings.after_hours_message) {
      setAfterHoursMsgURL(originalSettings.after_hours_message.url);
      setAfterHoursMsgType("audio");
      setAfterHoursMsgFile(
        [].concat([{ name: originalSettings.after_hours_message.name }])
      );
    } else {
      setAfterHoursMsgURL("");
      setAfterHoursMsgType("none");
      setAfterHoursMsgFile([]);
    }

    if (originalSettings.inbound_user) {
      setConnectToType("user");
      setConnectToUser(originalSettings.inbound_user.id);
    } else if (originalSettings.inbound_team) {
      setConnectToType("team");
      setConnectToTeam(originalSettings.inbound_team);
      setCallDistribution(originalSettings.call_distribution);
    } else {
      setConnectToType("none");
      setConnectToUser(null);
      setConnectToTeam(null);
    }
  }, [originalSettings]);

  const isSaveDisabledWorkingHours = () => {
    const originalAudioType =
      originalSettings?.working_hours_message === null ? "none" : "audio";

    const originalConnectType =
      originalSettings?.inbound_team === null ? originalSettings?.inbound_user === null ? "none" : "user" : "team";

    // 1] Check if there are incomplete fileds
    if (
      (connectToType === "user" && connectToUser === "") ||
      (connectToType === "team" && connectToTeam === "") ||
      (workingHoursMsgType !== originalAudioType &&
        workingHoursMsgType === "audio" &&
        workingHoursMsgFile?.length === 0)
    )
      return true;

    // 2] Check if a field has changed and can be updated

    // 2a] Check if audio field changed
    // If type changed
    if (workingHoursMsgType !== originalAudioType) {
      if (workingHoursMsgType === "none") return false;
      else if (workingHoursMsgType === "audio") {
        if (workingHoursMsgFile?.length !== 0) return false;
      }
    }
    // if type not changed and type is audio
    else {
      if (workingHoursMsgType === "audio") {
        if (workingHoursMsgFile?.length === 0) return false;
        else if (workingHoursFileChanged) return false;
      }
    }

    // 2b] Check if connect to field changed
    // if type changed -> enable save
    if (originalConnectType !== connectToType) return false;
    // else if type is unchanged
    else {
      if (
        connectToType === "user" &&
        connectToUser != originalSettings?.inbound_user?.id
      )
        return false;
      else if (connectToType === "team") {
        if (connectToTeam != originalSettings?.inbound_team) return false;
        else if (callDistribution != originalSettings?.call_distribution)
          return false;
      }
    }
    // if type is user(unchanged) but selected user is changed => enable save

    return true;
  };

  const isSaveDisabledAfterHours = () => {
    const originalType =
      originalSettings.after_hours_message === null ? "none" : "audio";

    // If type changed
    if (afterHoursMsgType !== originalType) {
      if (afterHoursMsgType === "none") return false;
      else if (afterHoursMsgType === "audio") {
        if (afterHoursMsgFile?.length !== 0) return false;
        else return true;
      }
    }
    // if type not changed and type is audio
    else {
      if (afterHoursMsgType === "audio") {
        if (afterHoursMsgFile?.length === 0) return false;
        else if (afterHoursFileChanged) return false;
        else return true;
      } else return true;
    }
  };

  const createRecording = async (audioFile) => {
    /*
      audioFile = file to be uploaded to cloud
    */
    const audioFormData = new FormData();
    audioFormData.append("audio_file", audioFile);

    try{
      const response=await JService.post("/api/v1/core/create-recording/",audioFormData)
      console.log(response)
      if(!response?.success) throw new Error(response?.message || 'Could not upload the file')
      return response.data.id;
    }catch(err) {
		enqueueSnackbar(err?.message, { variant: "error"})
    }
  };

  function updateVirtualNumberAPICall(payload) {
    JService.patch(`/api/v1/auth/virtual-numbers/calling/${originalSettings.id}/`, payload)
      .then((res) => {
		if(!res?.success) throw new Error(res?.message || "Error while updating virtual number")
		enqueueSnackbar(res?.message, { variant: "success"})
		setOriginalSettings(res.data);
		setAfterHoursFileChanged(false);
		setWorkingHoursFileChanged(false);
      
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  }

  function handleSave(type) {
    /*
      type can be 'working-hrs' OR 'after-hrs'
    */
    let payload = {};

    if (type === "working-hrs") {
      if (connectToType === "user")
        payload = { inbound_user_id: connectToUser };
      else if( connectToType === "none")
        payload = {
                    inbound_user_id: null,
                    inbound_team_id: null
                  };
      else if (connectToType === "team") {
        // Team is changed
        if (connectToTeam != originalSettings.inbound_team) {
          // When team is changed, generate default random routing_order
          const _temp = originalSettings.all_teams
            .filter((team) => team.id === connectToTeam)[0]
            .users.map((user) => user.id);

          payload = {
            inbound_team_id: connectToTeam,
            call_distribution: callDistribution,
            routing_order: _temp,
          };
        }
        // Team is not changed
        else {
          payload = {
            call_distribution: callDistribution,
          };
        }
      }

      if (workingHoursMsgType === "none" || workingHoursMsgFile.length === 0) {
        updateVirtualNumberAPICall({
          ...payload,
          working_hours_message_id: null,
        });
      } else if (workingHoursMsgType === "audio") {
        if (workingHoursFileChanged)
          createRecording(workingHoursMsgFile[0]).then((recordingId) =>
            updateVirtualNumberAPICall({
              ...payload,
              working_hours_message_id: recordingId,
            })
          );
        else {
          updateVirtualNumberAPICall(payload);
        }
      }
    } else if (type === "after-hrs") {
      if (afterHoursMsgType === "none" || afterHoursMsgFile.length === 0) {
        updateVirtualNumberAPICall({ after_hours_message_id: null });
      } else if (afterHoursMsgType === "audio") {
        if (afterHoursFileChanged)
          createRecording(afterHoursMsgFile[0]).then((recordingId) => {
            updateVirtualNumberAPICall({ after_hours_message_id: recordingId });
          });
        else {
          updateVirtualNumberAPICall(payload);
        }
      }
    }
  }

  const handleAudioFileUpload = async (event, setFile, setAudioURL) => {
    const audioFile = event.target.files[0];

    const loadAudioMetaData = (file) =>
      new Promise((res, rej) => {
        const audio = document.createElement("audio");
        audio.preload = "metadata";
        audio.onloadedmetadata = () => res(audio);
        audio.onerror = () => {
		  enqueueSnackbar("Please upload an audio file of the right format", { variant: "error"})
          event.target.files[0] = "";
        };
        audio.src = URL.createObjectURL(file);
      });

    const _audio = await loadAudioMetaData(audioFile);
    if (_audio.duration > 45 || _audio.duration == 0) {
	  enqueueSnackbar("Please upload a valid audio file of duration 1 - 45 seconds", { variant: "error"})
      event.target.value = "";
    } else {
      setFile([audioFile]);
      setAudioURL(URL.createObjectURL(audioFile));
    }
  };

  const UploadAudio = ({ file, setFile, setAudioURL, category }) => {
    return (
      <label htmlFor="upload-list-file" className="upload-file-label">
        {file.length === 0 && (
          <>
            <div className="no-file">
              <div>
                <a className="t6 ic-green-500">Click to upload</a>
                <span className="t6 medium-font">&nbsp;or drag and drop</span>
                <p className="t8 regular-font nc-gray-600">
                  File format: mp3 (max size: 10MB)
                </p>
              </div>
            </div>
            <input
              id="upload-list-file"
              placeholder="or drag and drop files here"
              className="t6 regular-font"
              name="file"
              type="file"
              // disabled={newListFile[0]?.name}
              accept={".mp3"}
              onChange={(e) => {
                handleAudioFileUpload(e, setFile, setAudioURL);
                if (category === "during") setWorkingHoursFileChanged(true);
                else if (category === "after") setAfterHoursFileChanged(true);
              }}
            />
          </>
        )}
        {file[0]?.name && (
          <>
            <div className="has-file">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={musicFileIcon} />
                <div
                  className="t6 regular-font"
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <p className="nc-gray-900 t6 regular-font">
                    {file[0].name.substring(0, 27)}
                    {file[0].name.length > 27 ? "…" : ""}
                  </p>

                  {!!file[0]?.size && (
                    <p className="nc-gray-600 t6 regular-font">
                      {formatFileSize(file[0]?.size)} z
                    </p>
                  )}
                </div>
              </div>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setFile([]);
                  setAudioURL("");
                }}
              />
            </div>
          </>
        )}
      </label>
    );
  };

  const WelcomeMessage = ({ category }) => {
    return (
      <>
        <label
          className="t6 nc-gray-900 medium-font"
          style={{ marginBottom: "12px" }}
        >
          Welcome message
        </label>
        <Select
          value={
            category === "during"
              ? workingHoursMsgType
              : category === "after"
              ? afterHoursMsgType
              : "none"
          }
          onChange={(e) => {
            if (category === "during") setWorkingHoursMsgType(e.target.value);
            else if (category === "after") setAfterHoursMsgType(e.target.value);
          }}
          sx={commonStyles.select}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) => {
            if (selected === "audio") return "Audio file";
            else
              return `No message (${
                category === "during"
                  ? "Connect directly"
                  : "Disconnect automatically"
              })`;
          }}
        >
          <MenuItem sx={commonStyles.menu_item} value="none">
            {`No message (${
              category === "during"
                ? "Connect directly"
                : "Disconnect automatically"
            })`}
            {category === "during" &&
              workingHoursMsgType === "none" &&
              topNavAccountSelectedAccountTickIcon()}
            {category === "after" &&
              afterHoursMsgType === "none" &&
              topNavAccountSelectedAccountTickIcon()}
          </MenuItem>
          <MenuItem sx={commonStyles.menu_item} value="audio">
            Audio file
            {category === "during" &&
              workingHoursMsgType === "audio" &&
              topNavAccountSelectedAccountTickIcon()}
            {category === "after" &&
              afterHoursMsgType === "audio" &&
              topNavAccountSelectedAccountTickIcon()}
          </MenuItem>
        </Select>

        {category === "during" && workingHoursMsgType === "audio" && (
          <>
            <UploadAudio
              file={workingHoursMsgFile}
              setFile={setworkingHoursMsgFile}
              setAudioURL={setWorkingHoursMsgURL}
              category="during"
            />
            {!!workingHoursMsgURL && (
              <div
                className="listen-btn"
                onClick={() => {
                  setaudioModalURL(workingHoursMsgURL);
                  setShowAudioModal(true);
                }}
              >
                <img
                  style={{ width: "18px" }}
                  src={listenIcon}
                  alt="listen icon"
                />
                <p>Listen</p>
              </div>
            )}
          </>
        )}

        {category === "after" && afterHoursMsgType === "audio" && (
          <>
            <UploadAudio
              file={afterHoursMsgFile}
              setFile={setAfterHoursMsgFile}
              setAudioURL={setAfterHoursMsgURL}
              category="after"
            />
            {!!afterHoursMsgURL && (
              <div
                className="listen-btn"
                onClick={() => {
                  setaudioModalURL(afterHoursMsgURL);
                  setShowAudioModal(true);
                }}
              >
                <img
                  style={{ width: "18px" }}
                  src={listenIcon}
                  alt="listen icon"
                />
                <p>Listen</p>
              </div>
            )}
          </>
        )}
      </>
    );
  };
  const  renderCallDistribution = (value) => {
    switch (value) {
      case 5:
        return "Simultaneous ringing";
      case 1:
        return "Round Robin";
      case 2:
        return "Fixed Order";
      case 3:
        return "Longest Idle";
      case 4:
        return "Sticky Agent";
      default:
        return "";
    }
  };
  return (
    <>
      <div>
        <p
          className="t5 medium-font"
          style={{ marginTop: "56px", marginBottom: "40px" }}
        >
          During business hours
        </p>

        <form
          className="new-contact-list-form add-contact-form virtual-numbers-form"
          style={{ maxWidth: "400px" }}
        >
        { reduxSubscription?.plan?.name?.includes("Professional") && <WelcomeMessage category="during" /> }

          <label
            className="t6 nc-gray-900 medium-font"
            style={{ marginBottom: "4px", marginTop: "32px" }}
          >
            Connect to
            <div className="connect-to-box">
              <p className="box-title t6 medium-font ">User/Team</p>
              <div className="options">
                <Select
                  value={connectToType}
                  onChange={(e) => {
                    setConnectToType(e.target.value);
                  }}
                  sx={{ ...commonStyles.select, width: "112px" }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={(p) => p === 'none' ? 'No one' : capitalizeString(p)}
                >
                  <MenuItem sx={commonStyles.menu_item} value="user">
                    User
                    {connectToType === "user" &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                  <MenuItem sx={commonStyles.menu_item} value="team">
                    Team
                    {connectToType === "team" &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                  <MenuItem sx={commonStyles.menu_item} value="none">
                    No one
                    {connectToType === "none" &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                </Select>

                {connectToType !== 'none' && <Select
                  value={
                    connectToType === "user" ? connectToUser : connectToTeam
                  }
                  onChange={(e) => {
                    if (connectToType === "user")
                      setConnectToUser(e.target.value);
                    else if (connectToType === "team")
                      setConnectToTeam(e.target.value);
                  }}
                  sx={{ ...commonStyles.select, width: "236px" }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={(selected) => {
                    if (connectToType === "user" && connectToUser == "")
                      return "";
                    else if (connectToType === "team" && connectToTeam == "")
                      return "";

                    if (connectToType === "user") {
                      const selectedUserData =
                        originalSettings?.all_users.filter(
                          (user) => user.id === connectToUser
                        )[0];

                      return (
                        selectedUserData ? 
                        (selectedUserData?.first_name +
                        selectedUserData?.last_name)?.trim() ?
                        selectedUserData?.first_name+" "+selectedUserData?.last_name
                        : selectedUserData?.email : null
                        );
                    } else if (connectToType === "team") {
                      const selectedTeamData =
                        originalSettings?.all_teams.filter(
                          (team) => team.id === connectToTeam
                        )[0];

                      return selectedTeamData?.name;
                    }
                  }}
                >
                  {connectToType === "user" &&
                    originalSettings.all_users.map((user) => {
                      return (
                        <MenuItem
                          sx={commonStyles.menu_item}
                          value={user.id}
                          key={user.id}
                        > <div>
                          {(user.first_name+user.last_name).trim() && <p className="t7 regular-font nc-gray-900" style={localStyles.pNoOverflow}>{user.first_name + " " + user.last_name}</p>}
                          <p className="t7 regular-font nc-gray-600" style={localStyles.pNoOverflow}>{user.email}</p>
                          </div>
                          {user.id === connectToUser &&
                            topNavAccountSelectedAccountTickIcon()}
                        </MenuItem>
                      );
                    })}

                  {connectToType === "team" &&
                    originalSettings.all_teams.map((team) => {
                      return (
                        <MenuItem
                          sx={commonStyles.menu_item}
                          value={team.id}
                          key={team.id}
                        >
                          {team.name}
                          {team.id === connectToTeam &&
                            topNavAccountSelectedAccountTickIcon()}
                        </MenuItem>
                      );
                    })}
                </Select>}
              </div>
            </div>
          </label>

          {connectToType === "team" && (
            <>
              <label
                className="t6 nc-gray-900 medium-font"
                style={{ marginBottom: "12px", marginTop: "32px" }}
              >
                Call Distribution
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "-150px",
                }}
              >
                <Select
                  value={callDistribution}
                  onChange={(e) => setCallDistribution(e.target.value)}
                  sx={{ ...commonStyles.select, width: "400px" }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={renderCallDistribution}
                  
                >
                  <MenuItem sx={commonStyles.menu_item} value={5}>
                    <div className="call-dist-options t6 regular-font">
                      <p className="nc-gray-900">Simultaneous ringing</p>
                      <p className="nc-gray-600">Calls ring on all available users' at once,</p>
                      <p className="nc-gray-600"> first to respond takes the call.</p>
                    </div>
                    {callDistribution === 5 && topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                  <MenuItem sx={commonStyles.menu_item} value={1}>
                    <div className="call-dist-options t6 regular-font">
                      <p className="nc-gray-900">Round Robin</p>
                      <p className="nc-gray-600">
                        Calls are distributed to users in a
                      </p>
                      <p className="nc-gray-600">rotational order.</p>
                    </div>
                    {callDistribution === 1 &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                  <MenuItem sx={commonStyles.menu_item} value={2}>
                    <div className="call-dist-options t6 regular-font ">
                      <p className="nc-gray-900">Fixed Order</p>
                      <p className="nc-gray-600">
                        Calls are distributed to users in a
                      </p>
                      <p className="nc-gray-600">predetermined order.</p>
                    </div>
                    {callDistribution === 2 &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                  <MenuItem sx={commonStyles.menu_item} value={3}>
                    <div className="call-dist-options t6 regular-font">
                      <p className="nc-gray-900">Longest Idle</p>
                      <p className="nc-gray-600">
                        Calls are distributed to the user who has
                      </p>
                      <p className="nc-gray-600">
                        been idle for the longest period of time.
                      </p>
                    </div>
                    {callDistribution === 3 &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                  <MenuItem sx={commonStyles.menu_item} value={4}>
                    <div className="call-dist-options t6 regular-font">
                      <p className="nc-gray-900">Sticky Agent</p>
                      <p className="nc-gray-600">
                        Connects caller to the same agent with whom
                      </p>
                      <p className="nc-gray-600">
                        they had conversation in previous call.
                      </p>
                    </div>
                    {callDistribution === 4 &&
                      topNavAccountSelectedAccountTickIcon()}
                  </MenuItem>
                </Select>
                {callDistribution === 2 && !!connectToTeam && (
                  <div
                    className="listen-btn"
                    onClick={() => setShowEditOrderModal(true)}
                    style={{
                      marginBottom: "10px",
                      marginLeft: "14px",
                    }}
                  >
                    <img
                      style={{ width: "16px" }}
                      src={editIcon}
                      alt="listen icon"
                    />
                    <p>Edit order</p>
                  </div>
                )}
              </div>
            </>
          )}

          <div style={{ margin: "40px 0" }}>
            <Button
              variant="filled"
              buttonText="Save"
              icon={{}}
              disabled={isSaveDisabledWorkingHours()}
              isLoading={false}
              hierarchy="green"
              onClick={() => handleSave("working-hrs")}
            />
          </div>
        </form>

        {reduxSubscription?.plan?.name?.includes("Professional") && <div>         
          <div
            style={{
              borderBottomWidth: "1px",
              borderBottomColor: "#E1E6E2",
              borderBottomStyle: "solid",
            }}
          ></div>

          <p className="t5 medium-font" style={{ margin: "40px 0" }}>
            After business hours
          </p>
          <form
            className="new-contact-list-form add-contact-form virtual-numbers-form"
            style={{ maxWidth: "400px" }}
          >
            <WelcomeMessage category="after" />
            <div style={{ margin: "40px 0" }}>
              <Button
                variant="filled"
                buttonText="Save"
                icon={{}}
                disabled={isSaveDisabledAfterHours()}
                isLoading={false}
                hierarchy="green"
                onClick={() => handleSave("after-hrs")}
              />
            </div>
          </form>
          </div>}
      </div>

      <GModal
        visible={showAudioModal}
        closeModal={() => {
          setShowAudioModal(false);
        }}
        heading={"Message audio"}
        body={
          <>
            <audio
              controls
              style={{
                width: "100%",
                margin: 0,
                padding: 0,
                display: "block",
              }}
            >
              EditOrderModal
              <source src={audioModalURL} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </>
        }
        modalStyle={{ height: "116px", width: "448px", padding: "32px 24px" }}
        bottomButtons={[]}
      />

      {showEditOrderModal && (
        <EditOrderModal
          originalSettings={originalSettings}
          setOriginalSettings={setOriginalSettings}
          onClose={() => setShowEditOrderModal(false)}
          connectToTeam={connectToTeam}
          callDistribution={callDistribution}
        />
      )}

    </>
  );
}
const localStyles={
  pNoOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: '230px',
    margin: 0,
  },
}

export default IncomingTab;
