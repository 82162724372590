import { useEffect, useState } from "react";
import { downloadIcon, searchIcon } from "../../utility-functions/assetLoader";
import classes from "./Transcript.module.css";
import { enqueueSnackbar } from "notistack";

const Transcript = ({ transcript, handleSeekToTime, error }) => {
  //States
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTranscript, setFilteredTranscript] = useState(transcript);

  //Handlers
  function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const searchHandler = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = transcript.filter((item) =>
      item.text?.toLowerCase().includes(query)
    );
    setFilteredTranscript(filtered);
  };

  const downloadTranscriptHandler = () => {
    if (transcript && transcript.length > 0) {
      let filteredTranscript;
      filteredTranscript = transcript.map((item) => {
        return `[${formatTime(item.start)}] Speaker ${item.speaker}: ${item.text}`;
      });
      const textToDownload = filteredTranscript.join("\n");
      const blob = new Blob([textToDownload], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "transcript.txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      console.error("No transcript data available for download");
    }
  };

  //Effects
  useEffect(() => {
    if (error !== "") {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [error]);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          {searchIcon()}
          <input
            type="text"
            placeholder="Search"
            className="regular-font text-sm"
            value={searchQuery}
            onChange={searchHandler}
          />
        </div>
        <button onClick={downloadTranscriptHandler}>{downloadIcon()}</button>
      </div>
      {filteredTranscript?.map((item, index) => (
        <div
          onClick={() => handleSeekToTime(item?.start)}
          key={index}
          className={classes.dialogue}
        >
          <span className="text-sm regular-font">{formatTime(item.start)}</span>
          <h6 className="medium-font text-sm">
            {"Speaker " + item.speaker + ":"}
          </h6>
          <p className="regular-font text-sm">{item.text}</p>
        </div>
      ))}
    </div>
  );
};

export default Transcript;
