import { useEffect } from "react";
import classes from "./Summary.module.css";
import { enqueueSnackbar } from "notistack";

const Summary = ({ error, summary, questions, actionItems }) => {
  //Effects
  useEffect(() => {
    if (error !== "") {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [error]);
  return (
    <div className={classes.summaryContainer}>
      <div>
        <h6 className="medium-font text-sm">Summary</h6>
        <p className="regular-font text-sm">{summary}</p>
      </div>
      {/* <div>
        <h6 className="medium-font text-sm">Questions</h6>
        <div className={classes.questions}>
          {questions?.map((item, index) => (
            <div key={index}>
              <p className="regular-font text-sm">{"Q. " + item.question}</p>
              <p className="regular-font text-sm">{"A. " + item.answer}</p>
            </div>
          ))}
        </div>
      </div> */}
      <div>
        <h6 className="medium-font text-sm">Action items</h6>
        <p className="regular-font text-sm">{actionItems}</p>
      </div>
    </div>
  );
};

export default Summary;
