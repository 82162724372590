import "../../design/components/gtableStyles.css";
import Button from "../../design/Button";
import GHeader from "../../design/components/GHeader";
import GTable from "../../design/components/GTable";
import userEditIcon from "../../../assets/call-logs-edit.svg";
import "./usersStyles.css";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Box, Chip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GModal from "../../design/components/GModal";
import Select from "@mui/material/Select";
import Styles from "../../design/styles";
import Colors from "../../design/colors";
import { JService } from "../../api-service/ApiService";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeString,
  fetchData,
  filtersReducer,
  getOrgURLParam,
  getPermissionId,
  validateEmail,
} from "../../../utility-functions";
import { topNavAccountSelectedAccountTickIcon } from "../../../utility-functions/assetLoader";
import usersEmptyStateImage from "../../../assets/users-empty-state.svg";
import {
  CustomAvatar,
  CustomChip,
  CustomInput,
} from "../../design/components/customComponents";
import { setSubscriptionDetails } from "../../features/account/account";
import { commonStyles } from "../../signup/commonStyles";
import { enqueueSnackbar } from "notistack";
import { CustomTooltip } from "../../design/components/customComponents";
import { Filters } from "../../dashboard/Filters";
import { hasPermission } from "../../features/permission/auth";

let RETURN_DATA = null;

const filtersList = [
  {
    name: "Role",
    toggle: false,
    isSearch: false,
    payloadFor: "roles",
    permission: "manage roles",
  },
  { name: "License", toggle: false, isSearch: false, payloadFor: "licenses" }, // ⏳enable
  { name: "Status", toggle: false, isSearch: false, payloadFor: "statuses" },
];

const generateUsersTableRowsData = (data) => {
  const returnData = Object.values(data).reduce((cumulative, key) => {
    let {
      id,
      first_name,
      last_name,
      email,
      teams, // ⏳enable
      roles,
      status,
      inbound_virtual_numbers,
      outbound_virtual_numbers,
      number,
      license,
      is_active,
      addons,
    } = key;

    // virtual_numbers = virtual_numbers?.find(vn => vn?.number_type?.toLowerCase() === 'calls') || (Array.isArray(virtual_numbers) && virtual_numbers?.length) ? virtual_numbers[0] : ''

    let formattedVirtualNumbers = inbound_virtual_numbers.concat(
      outbound_virtual_numbers
    );

    // removing duplicates since user can have same number in inbound and outbound
    const seen = new Set();
    formattedVirtualNumbers = formattedVirtualNumbers.filter((vn) => {
      const duplicate = seen.has(vn.number);
      seen.add(vn.number);
      return !duplicate;
    });

    let row = {};

    row["userVirtualNumber"] =
      formattedVirtualNumbers.length > 0 ? formattedVirtualNumbers : "";
    let _addons = addons?.map(
      (addon) => addon.charAt(0).toUpperCase() + addon.slice(1)?.toLowerCase()
    );
    row["userLicense"] = [..._addons];

    row = {
      ...row,
      id,
      userUser: { firstName: first_name, lastName: last_name, email },
      // CLARIFY: check for reminder-calls || calls ?
      // userVirtualNumber: virtual_numbers,
      userRole: roles,
      userTeam: teams, // ⏳enable
      userStatus: status,
      userEdit: "edit",
      inboundVirtualNumbers: inbound_virtual_numbers,
      outboundVirtualNumbers: outbound_virtual_numbers,
      phoneNumber: number?.phone_number,
      license,
      is_active,
    };

    cumulative.push(row);
    return cumulative;
  }, []);
  RETURN_DATA = returnData;
  return returnData;
};

const getUsersRowClassName = () => "users-table-row";

const USERSTATUSCOLORS = (status) => {
  if (["resend invitation", "active"].includes(status))
    return Colors.ic_green_500;
  else if (status === "invite sent") return Colors.nc_gray_900;
  else if (status === "inactive") return Colors.nc_gray_600;
};

const initialState = {
  //available
  licenses: {
    all: [
      { id: "Plan", name: "Plan" },
      { id: "Viewer", name: "Viewer" },
      { id: "Autodial", name: "Autodial" },
      { id: "Browser calling", name: "Browser calling" },
    ],
    selected: [],
  }, // ⏳enable
  roles: { all: [], selected: [] },
  statuses: {
    all: [
      { id: "Active", name: "Active" },
      { id: "Inactive", name: "Inactive" },
      { id: "Resend Invitation", name: "Resend Invitation" },
      { id: "Invitation Sent", name: "Invitation Sent" },
    ],
    selected: [],
  },
  currentSet: "",
  searchText: "",
  searchFields: [],
};
const API_BASE_URL = process.env.REACT_APP_BETA;

export default function Users() {
  const dispatch = useDispatch();

  const reduxAccount = useSelector((state) => state?.account?.value?.data);
  const reduxSubscription = useSelector(
    (state) => state?.account?.value?.subscription
  );
  const reduxOrg = useSelector((state) => state?.account?.value);
  const {
    canCreateEditUsers,
    canManageRoles,
    canManageNumbers,
    canManageBillingSubscription} = useSelector((state) => state?.auth?.permissions);
  const userRoles = useSelector((state) => state?.auth?.userRoles);

  const [showLoader, setShowLoader] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [controller, setController] = useState(1); // hook up to useEffect and change controller to fetch data
  const [masterRows, setMasterRows] = useState([]);
  const [rows, setRows] = useState([]);
  // response data for edit user
  const [data, setData] = useState([]);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [usersName, setUsersName] = useState("");
  const [virtualNumbersAvailability, setVirtualNumbersAvailability] = useState(
    []
  );
  const [showAll, setShowAll] = useState(false);
  const [reactivateUserModalVisible, setReactivateUserModalVisible] =
    useState(false);
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [updatePayload, setUpdatePayload] = useState(null);
  const [addonLicenses, setAddonLicenses] = useState([]);
  // the user in the row of the most recent threedots click event.composedPath
  const [user, setUser] = useState(null);
  const [csvData, setCSVData] = useState({
    headers: [
      ["User"],
      ["Phone number"],
      ["Incoming Virtual numbers"],
      ["Outgoing Virtual numbers"],
      ["Role"],
      ["Team"], // ⏳enable
      ["Status"],
    ],
    data: [],
    ids: [],
  });
  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );
  const [editUserValues, setEditUserValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    virtualNumbers: [],
    roles: [],
    license: "",
    addonLicenses: [],
  });
  const [addonsToDisplay, setAddonsToDisplay] = useState([]);

  const licenseOptions = useMemo(() => {
    if (!reduxSubscription || !Object.keys(reduxSubscription)?.length)
      return [];
    return [
      {
        type: `${reduxSubscription?.plan?.name?.split("-")?.[0]} plan`,
        count: reduxSubscription?.plan_licenses?.free,
      },
      {
        type: "Viewer",
        count: reduxSubscription?.viewer_licenses?.free,
      },
    ];
  }, [reduxSubscription]);

  const handleCancelReactivateUser = () => {
    setReactivateUserValues({
      licenseType: `${reduxSubscription?.plan?.name?.split("-")[0]} plan`,
    });
    setReactivateUserModalVisible(false);
  };
  const handleReactivateUser = (e, type) => {
    const payload = {
      user_id: user?.id,
      is_active: true,
      license:
        type ||
        (reactivateUserValues?.licenseType?.includes("plan")
          ? "Plan"
          : "Viewer"),
    };
    handleEditUserSave(null, payload);
  };
  const handleDeactivateUser = () => {
    const payload = {
      user_id: user?.id,
      is_active: false,
    };
    handleEditUserSave(null, payload);
  };

  const fetchSubscriptionData = () => {
    if (!canManageBillingSubscription) return;
    JService.get(
      API_BASE_URL + `/api/v1/subscriptions/subscription/${getOrgURLParam()}`
    )
      .then((res) => {
        if (!res.success)
          throw new Error(
            res?.message || "Could not retrieve subscription details"
          );
        if (res.data && Object.keys(res?.data)?.length) {
          dispatch(setSubscriptionDetails(res.data));
          setAddonLicenses(res.data?.addon_licenses);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // when rows change update csvData
  useEffect(() => {
    setCSVData((prevState) => {
      let newIds = [];
      const newData = [];

      for (let i = 0; i < rows?.length; ++i) {
        let item = rows[i];
        if (!newIds?.includes(item?.id)) {
          newIds.push(item?.id);
          let temp = [
            item?.userUser?.firstName + " " + item?.userUser?.lastName,
            item?.phoneNumber,
            // Object.keys(item?.userVirtualNumber)?.length > 0 ? item?.userVirtualNumber?.country_code+' '+item?.userVirtualNumber?.number: '',
            item?.inboundVirtualNumbers
              .map((vn) => {
                return vn?.country_code + " " + vn?.number;
              })
              .join(", "),
            item?.outboundVirtualNumbers
              .map((vn) => {
                return vn?.country_code + " " + vn?.number;
              })
              .join(", "),
            item?.userRole?.map((role) => role.title)?.join(", "),
            Array.isArray(item?.userTeam) && item?.userTeam?.length > 0
              ? item?.userTeam?.map((ut) => ut?.name)?.join(", ")
              : "", // change separator to ',' if necessary for csv data // ⏳enable
            item?.userStatus,
          ];
          newData.push(temp);
        }
      }
      return { ...prevState, data: newData, ids: newIds };
    });
  }, [rows]);

  const [addNewUserModalVisible, setAddNewUserModalVisible] = useState(false);
  const [addUserValues, setAddUserValues] = useState({
    email: { value: "", error: null },
    addonLicenses: [],
    virtualNumber: "",
    roles: [],
    licenseType:
      reduxSubscription?.plan_licenses?.free > 0
        ? `${reduxSubscription?.plan?.name?.split("-")[0]} plan`
        : "Viewer",
  });
  const [reactivateUserValues, setReactivateUserValues] = useState({
    licenseType:
      reduxSubscription?.plan_licenses?.free > 0
        ? `${reduxSubscription?.plan?.name?.split("-")[0]} plan`
        : "Viewer",
  });
  const addUserButton = canCreateEditUsers ? (
    <CustomTooltip
      tooltipTitle={
        reduxSubscription?.status !== "TRIAL" &&
        reduxSubscription?.plan_licenses?.free <= 0 &&
        reduxSubscription?.viewer_licenses?.free <= 0
          ? "Add plan or viewer license to invite user"
          : ""
      }
    >
      <span>
        <Button
          variant="filled"
          buttonText="Invite user"
          disabled={
            reduxSubscription?.status !== "TRIAL" &&
            reduxSubscription?.plan_licenses?.free <= 0 &&
            reduxSubscription?.viewer_licenses?.free <= 0
          }
          styleOverride={{ marginTop: "-24px", marginBottom: "24px" }}
          isLoading={false}
          hierarchy="green"
          onClick={handleAddUsersClick}
        />
      </span>
    </CustomTooltip>
  ) : null;

  // modal bodies
  const deleteUserModalBody = deleteModalVisible ? (
    <Box sx={{ width: user.license !== "Viewer" ? "481px" : "384px" }}>
      <p className="regular-font t7 nc-gray-900">
        Are you sure you want to permanently delete&nbsp;
        <span
          className="medium-font t7 nc-gray-900"
          style={{ textDecoration: "underline" }}
        >
          {`${usersName}'s `}
        </span>
        FreJun account?
      </p>
      {user.license !== "Viewer" && (
        <ul
          className="t7 regular-font delete-modal-ul"
          style={{ color: "#464c48" }}
        >
          <li>You can add them anytime by inviting them with email address.</li>
          <li>
            You will continue to have access to their call data, plan license
            and virtual number.
          </li>
        </ul>
      )}
    </Box>
  ) : null;

  const deactivateUserModalBody = deactivateModalVisible ? (
    <Box>
      <p className="regular-font t7 nc-gray-900" style={{ lineHeight: "180%" }}>
        Are you sure you want to temporarily deactivate&nbsp;
        <span
          className="medium-font t7 nc-gray-900"
          style={{ textDecoration: "underline" }}
        >
          {`${usersName}'s `}
        </span>{" "}
        FreJun account?
      </p>
    </Box>
  ) : null;

  // ⏳enable

  function handleAddToField(value, setterFn, field) {
    if (value instanceof Array) value = value?.reverse()[0];
    if (!value) return;
    setterFn((prevState) => {
      if (
        typeof value === "object" &&
        prevState[field]?.find((elem) => elem.id === value.id)
      )
        return prevState;
      else if (prevState[field].includes(value)) return prevState;
      return { ...prevState, [field]: [...prevState[field], value] };
    });
    setUpdatePayload((prev) => ({ ...prev, [field]: [] }));
  }

  function handleRemoveFromField(value, selected, setterFn, field) {
    let temp = selected?.filter((selected) => selected !== value);
    setterFn((prevState) => {
      return { ...prevState, [field]: temp };
    });
    setUpdatePayload((prev) => ({ ...prev, [field]: [] }));
  }

  function handleUpdateAddonOptions(updateType, addonArg) {
    const addon = Array.isArray(addonArg)
      ? addonArg[addonArg.length - 1]
      : addonArg;
    if (updateType === "add") {
      setAddonsToDisplay((prev) => {
        const temp = [...prev];
        const addonInTemp = temp.findIndex((elem) => elem.name === addon);
        if (addonInTemp !== -1) {
          temp[addonInTemp] = {
            ...temp[addonInTemp],
            licenses: {
              ...temp[addonInTemp]["licenses"],
              free: temp[addonInTemp]["licenses"]["free"] + 1,
            },
          };
          return temp;
        }
        return [
          ...temp,
          {
            name: addon,
            licenses: {
              free: 1,
            },
          },
        ];
      });
    } else {
      setAddonsToDisplay((prev) => {
        let temp = [...prev];
        const addonInTemp = temp.findIndex((elem) => elem.name === addon);
        if (addonInTemp !== -1) {
          temp[addonInTemp] = {
            ...temp[addonInTemp],
            licenses: {
              ...temp[addonInTemp]["licenses"],
              free: temp[addonInTemp]["licenses"]["free"] - 1,
            },
          };
          temp = temp.filter((elem) => elem.licenses?.free > 0);
        }
        return temp;
      });
    }
  }

  // --enable end

  const addonLicenseOptions = useMemo(() => {
    const options =
      addonLicenses.filter((addon) => addon.licenses?.free > 0) || [];
    setAddonsToDisplay(options || []);
    return options;
  }, [addonLicenses]);

  const editUserModalBody = editModalVisible ? (
    <Box>
      <form
        style={{ display: "flex", flexDirection: "column", width: "400px" }}
      >
        <div style={{ display: "flex" }}>
          {/* firstname */}
          <label
            className="t7 nc-gray-900 medium-font"
            style={{
              marginBottom: "24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "stretch",
            }}
          >
            First name
            <input
              value={editUserValues?.first_name}
              type="text"
              className="t7 regular-font nc-gray-900"
              style={{
                width: "192px",
                marginTop: "4px",
                padding: "8px 12px",
                borderRadius: "4px",
                border: "1px solid #E1E6E2",
                marginRight: "16px",
              }}
              data-edittype="first_name"
              onChange={handleEditUserChange}
            />
          </label>

          {/* lastname */}
          <label
            className="t7 nc-gray-900 medium-font"
            style={{
              marginBottom: "24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "stretch",
            }}
          >
            Last name
            <input
              value={editUserValues?.last_name}
              type="text"
              className="t7 regular-font nc-gray-900"
              style={{
                width: "192px",
                marginTop: "4px",
                padding: "8px 12px",
                borderRadius: "4px",
                border: "1px solid #E1E6E2",
              }}
              data-edittype="last_name"
              onChange={handleEditUserChange}
            />
          </label>
        </div>
        {/* email */}
        <label
          className="t7 nc-gray-900 medium-font"
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
          Email
          <input
            disabled={true}
            value={editUserValues?.email}
            type="email"
            className="t7 regular-font nc-gray-900"
            style={{
              marginTop: "4px",
              padding: "8px 12px",
              borderRadius: "4px",
              border: "1px solid #E1E6E2",
              background: Colors.nc_gray_100,
              color: Colors.nc_gray_600,
            }}
            data-edittype="email"
            onChange={handleEditUserChange}
          />
        </label>

        {canManageRoles && (
          <>
            <label
              className="t7 nc-gray-900 medium-font"
              style={{ marginBottom: "4px" }}
            >
              Role
            </label>
            <Select
              value={editUserValues?.roles || []}
              multiple={true}
              multiline={true}
              autoFocus={false}
              onChange={(e) =>
                handleAddToField(e.target.value, setEditUserValues, "roles")
              }
              sx={{
                ...selectStyles,
                "&:focus .MuiOutlinedInput-input": {
                  border: "none",
                  outline: "none",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 12px 8px 12px",
                  border: "none",
                  outline: "none",
                },
                "& .Mui-disabled": {
                  cursor: "not-allowed",
                },
              }}
              renderValue={(selected) => {
                if (availableData?.roles?.all?.length === 0)
                  return (
                    <p className="regular-font t7 nc-gray-400">
                      No role available
                    </p>
                  );
                if (!selected?.length)
                  return (
                    <p className="regular-font t7 nc-gray-400">Select roles</p>
                  );
                // check selected to see all seleted values, at this point, selected has duplicates
                // removing duplicates from rendering, remove duplicates from Select value before sending
                let temp = selected?.reduce(function (x, y) {
                  if (x.indexOf(y) < 0) x.push(y);
                  return x;
                }, []);
                return (
                  <Box sx={localStyles.chipContainer}>
                    {temp?.map((value) => {
                      if (
                        !value ||
                        (Array.isArray(value) && value?.length === 0)
                      )
                        return;
                      let id =
                        value instanceof Array
                          ? value[0]?.split(",")[1]
                          : value?.toString()?.split(",")[1];
                      let name =
                        value instanceof Array
                          ? value[0]?.split(",")[0]
                          : value?.toString()?.split(",")[0];
                      return (
                        <CustomChip
                          key={id}
                          label={name}
                          onDelete={(e) => {
                            e.stopPropagation();
                            handleRemoveFromField(
                              value,
                              selected,
                              setEditUserValues,
                              "roles"
                            );
                          }}
                        />
                      );
                    })}
                  </Box>
                );
              }}
              placeholder="Select roles"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {availableData?.roles?.all?.map((role) => {
                const selected = editUserValues?.roles
                  ?.map((role) => parseInt(role.split(",")?.[1], 10))
                  ?.includes(role.id);
                return (
                  <MenuItem
                    sx={localStyles.flexRow}
                    value={role.name + "," + role.id}
                    key={role.id}
                    disabled={editUserValues.roles?.length >= 3 && !selected}
                  >
                    <span>{role.name}</span>
                    <span>
                      {selected && topNavAccountSelectedAccountTickIcon()}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          </>
        )}
        {canManageNumbers && (
          <>
            <label
              className="t7 nc-gray-900 medium-font"
              style={{ marginBottom: "4px" }}
            >
              Virtual number (outgoing calls only)
            </label>
            <Select
              multiple={true}
              value={editUserValues?.virtualNumbers}
              onChange={(e) =>
                handleAddToField(
                  e.target.value,
                  setEditUserValues,
                  "virtualNumbers"
                )
              }
              sx={selectStyles}
              displayEmpty
              renderValue={(selected) => {
                if (virtualNumbersAvailability?.length === 0)
                  return (
                    <p className="regular-font t7 nc-gray-400">
                      No number available
                    </p>
                  );
                if (!selected)
                  return (
                    <p className="regular-font t7 nc-gray-400">Select number</p>
                  );
                let temp = selected?.reduce(function (x, y) {
                  if (x.indexOf(y) < 0) x.push(y);
                  return x;
                }, []);
                return (
                  <Box sx={localStyles.chipContainer}>
                    {temp?.map((value) => {
                      if (
                        !value ||
                        (Array.isArray(value) && value?.length === 0)
                      )
                        return;
                      let id =
                        value instanceof Array
                          ? value[0]?.split(",")[1]
                          : value?.toString()?.split(",")[1];
                      let name =
                        value instanceof Array
                          ? value[0]?.split(",")[0]
                          : value?.toString()?.split(",")[0];
                      return (
                        <CustomChip
                          key={id}
                          label={name}
                          onDelete={(e) => {
                            e.stopPropagation();
                            handleRemoveFromField(
                              value,
                              selected,
                              setEditUserValues,
                              "virtualNumbers"
                            );
                          }}
                        />
                      );
                    })}
                  </Box>
                );
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {virtualNumbersAvailability?.map((vn, index) => {
                const selected = editUserValues?.virtualNumbers
                  ?.map((vn) => parseInt(vn.split(",")?.[1], 10))
                  ?.includes(vn.id);
                return (
                  <MenuItem
                    key={vn.id}
                    value={`${vn.name || vn.country_code + " " + vn.number},${vn.id}`}
                    sx={commonStyles.selectMenuItemStyles}
                  >
                    <span>{vn.name || vn.country_code + " " + vn.number}</span>
                    {selected && (
                      <span>{topNavAccountSelectedAccountTickIcon()}</span>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        )}

       {/*License type*/}
       {reduxSubscription?.status !== 'TRIAL' && <><label
          className="t7 nc-gray-900 medium-font"
          style={{ marginBottom: "4px" }}
        >
          License
        </label>
        <Select
          value={editUserValues?.license}
          onChange={
           (e)=>handleEditUserChange(e, 'license', e.target.value)
          }
          sx={selectStyles}
          placeholder="Select license"
          renderValue={(selected) => {
            if (!selected)
              return (
                <p className="regular-font t7 nc-gray-400">Select license</p>
              );
            return <p className="regular-font t7 nc-gray-900">{selected}</p>;
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {licenseOptions?.map((elem)=>{
            return (
              <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem.type} key={elem.type} disabled={elem.count<=0 && ((user.license!==elem.type) || (user.license!=='Plan' && elem.type.includes('plan')))}>
                <span>{elem.type}</span>{editUserValues?.license===elem.type && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
              </MenuItem>
            )
          })}
        </Select>
        <label
          className="t7 nc-gray-900 medium-font"
          style={{ marginBottom: "4px" }}
        >
          Add-ons <span className="nc-gray-800 light-font">(optional)</span>
        </label>
        <Select
          value={
            Array.isArray(editUserValues?.addonLicenses)
              ? editUserValues.addonLicenses
              : []
          }
          multiple={true}
          onChange={(e) => {
            handleUpdateAddonOptions("remove", e.target.value);
            handleAddToField(
              e.target.value,
              setEditUserValues,
              "addonLicenses"
            );
          }}
          sx={selectStyles}
          renderValue={(selected) => {
            if (!selected?.length) {
              return (
                <p className="regular-font t7 nc-gray-400">Select add-ons</p>
              );
            }
            return (
              <Box sx={localStyles.chipContainer}>
                {selected.map((addon) => {
                  return (
                    <CustomChip
                      key={addon}
                      label={capitalizeString(addon)}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleRemoveFromField(
                          addon,
                          selected,
                          setEditUserValues,
                          "addonLicenses"
                        );
                        handleUpdateAddonOptions("add", addon);
                      }}
                    />
                  );
                })}
              </Box>
            );
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
        {addonsToDisplay?.map((addon) => (
          <MenuItem 
            value={addon.name} 
            key={addon.name} 
            sx={localStyles.flexRow}
          >
            <span>{capitalizeString(addon.name)}</span>
            <span>{editUserValues.addonLicenses?.includes(addon.name) && topNavAccountSelectedAccountTickIcon()}</span>
          </MenuItem>
        ))}
      </Select>
      </>}
      </form>
    </Box>
  ) : null;

  // --enable end
  const addNewUserModalBody = addNewUserModalVisible ? (
    <Box>
      <form
        style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
      >
        <CustomInput
          label="Email"
          handleChange={handleAddNewUserChange}
          handleFocus={handleEmailFocus}
          handleBlur={handleAddUserEmailBlur}
          type="email"
          style={{ fontSize: "16px" }}
          value={addUserValues?.email}
          name="email"
        />

        {/*License type*/}
        {reduxSubscription?.status !== 'TRIAL' && <>
        <label
          className="t7 nc-gray-900 medium-font"
          style={{ marginBottom: "4px" }}
        >
          Plan license
        </label>
        <Select
          value={addUserValues?.licenseType}
          onChange={(e) =>
            setAddUserValues((prevState) => ({
              ...prevState,
              licenseType: e.target.value,
            }))
          }
          sx={selectStyles}
          placeholder="Select license"
          renderValue={(selected) => {
            if (!selected)
              return (
                <p className="regular-font t7 nc-gray-400">Select license</p>
              );
            return <p className="regular-font t7 nc-gray-900">{selected}</p>;
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {licenseOptions?.map((elem)=>{
            return (
              <MenuItem sx={commonStyles.selectMenuItemStyles} value={elem.type} key={elem.type} disabled={elem.count<=0}>
                <span>{elem.type}</span>{addUserValues?.licenseType===elem.type && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
              </MenuItem>
            )
          })}
        </Select>
        </>
        }

         {/* role --mui select used */}
         {canManageRoles && <>
        <label
          className="t7 nc-gray-900 medium-font"
          style={{ marginBottom: "4px" }}
        >
          Role
        </label>
        <Select
          value={addUserValues?.roles || []}
          multiple={true}
          autoFocus={false}
          onChange={(e) => handleAddToField(e.target.value, setAddUserValues, 'roles')}
          sx={{
            ...selectStyles,
            "&:focus .MuiOutlinedInput-input": {
              border: "none",
              outline: "none",
            },
            "& .MuiOutlinedInput-input": {
              padding: "8px 12px 8px 12px",
              border: "none",
              outline: "none",
            },
            "& .Mui-disabled": {
              cursor: "not-allowed",
            },
          }}
          renderValue={(selected) => {
            if (availableData?.roles?.all?.length === 0)
              return (
                <p className="regular-font t7 nc-gray-400">No role available</p>
              );
            if (!selected?.length)
              return (
                <p className="regular-font t7 nc-gray-400">Select roles</p>
              );
            // check selected to see all seleted values, at this point, selected has duplicates
            // removing duplicates from rendering, remove duplicates from Select value before sending
            let temp = selected?.reduce(function (x, y) {
              if (x.indexOf(y) < 0) x.push(y);
              return x;
            }, []);
            return (
              <Box sx={localStyles.chipContainer}>
                {temp?.map((value) => {
                  if (!value || (Array.isArray(value) && value?.length === 0))
                    return;
                  let id =
                    value instanceof Array
                      ? value[0]?.split(",")[1]
                      : value?.toString()?.split(",")[1];
                  let name =
                    value instanceof Array
                      ? value[0]?.split(",")[0]
                      : value?.toString()?.split(",")[0];
                  return (
                    <CustomChip
                      key={id}
                      label={name}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleRemoveFromField(value, selected, setAddUserValues, 'roles');
                      }}
                    />
                  );
                })}
              </Box>
            );
          }}
          placeholder="Select roles"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {availableData?.roles?.all?.map((role) => {
            const selected = addUserValues.roles?.map(role => parseInt(role.split(',')?.[1], 10))?.includes(role.id)
            return (
            <MenuItem
             sx={localStyles.flexRow} 
             value={role.name + "," + role.id} 
             key={role.id}
             disabled={addUserValues.roles?.length >= 3 && !selected}
             >
              <span>{role.name}</span>
              <span>{selected && topNavAccountSelectedAccountTickIcon()}</span>
            </MenuItem>
          )})}
        </Select>
        </>}
        {/* virtualNumber location --mui select used */}
        {canManageNumbers && <>
        <label
          className="t7 nc-gray-900 medium-font"
          style={{ marginBottom: "4px" }}
        >
          Virtual number
        </label>
        <Select
          value={addUserValues?.virtualNumber}
          onChange={(e) =>
            setAddUserValues((prevState) => ({
              ...prevState,
              virtualNumber: e.target.value,
            }))
          }
          sx={selectStyles}
          placeholder="Select a location"
          displayEmpty
          renderValue={(selected) => {
            if (virtualNumbersAvailability?.length === 0)
              return (
                <p className="regular-font t7 nc-gray-400">
                  No number available
                </p>
              );
            if (!selected)
              return (
                <p className="regular-font t7 nc-gray-400">Select number</p>
              );
            return <p className="regular-font t7 nc-gray-900">{selected.name || selected.country_code+' '+selected.number}</p>;
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {/* <MenuItem value="">No location selected</MenuItem> */}
          {virtualNumbersAvailability?.map((vn, index) => (
            <MenuItem
              key={vn.id}
              value={vn}
              sx={commonStyles.selectMenuItemStyles}
              //disabled={!vnLocation?.count}
            >
              <span>{vn.name || `${vn.country_code} ${vn.number}`}</span>
              {addUserValues?.virtualNumber === vn && <span>{topNavAccountSelectedAccountTickIcon()}</span>}
            </MenuItem>
          ))}
        </Select>
        </>}
        {reduxSubscription?.status !== 'TRIAL' && <>
        <label
          className="t7 nc-gray-900 medium-font"
          style={{ marginBottom: "4px" }}
        >
          Add-ons <em className="nc-gray-800 light-font">(optional)</em>
        </label>
        <Select
          multiple
          disabled={!addonLicenseOptions.length}
          value={addUserValues?.addonLicenses || []}
          onChange={(e) => handleAddToField(e.target.value, setAddUserValues, 'addonLicenses')}
          sx={selectStyles}
          placeholder="Select add-ons"
          renderValue={(selected) => {
            if (!selected?.length) {
              return (
                <p className="regular-font t7 nc-gray-400">Select add-ons</p>
              );
            }
            return (
              <Box sx={localStyles.chipContainer}>
                {selected.map((addon) => {
                  return (
                    <CustomChip
                        key={addon}
                        label={capitalizeString(addon)}
                        onDelete={(e) => {
                          e.stopPropagation();
                          handleRemoveFromField(addon, selected, setAddUserValues, 'addonLicenses');
                        }}
                      />
                  );
                })}
              </Box>
            )}}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {addonLicenseOptions.map((addon) => (
            <MenuItem
              key={addon.id}
              value={addon.name}
              sx={localStyles.flexRow}
            >
              <span>{capitalizeString(addon.name)}</span>
              <span>{addUserValues.addonLicenses?.includes(addon.name) && topNavAccountSelectedAccountTickIcon()}</span>
            </MenuItem>
          ))}
        </Select>
        </>}
      </form>
    </Box>
  ) : null;

  // when filters are selected this useEffect is triggered
  useEffect(() => {
    onSearch(availableData?.searchText);
  }, [availableData]); //eslint-disable-line

  // modal handlers
  // onClick for add user button
  function handleAddUsersClick(e) {
    if (editModalVisible) setEditModalVisible(false);
    if (deleteModalVisible) setDeleteModalVisible(false);
    setAddNewUserModalVisible(true);
  }

  function handleEmailFocus(e) {
    setAddUserValues((prev) => ({
      ...prev,
      email: { ...prev.email, error: null },
    }));
  }
  function handleAddUserEmailBlur(e) {
    console.log("blur");
    // if(reduxSubscription?.free_licenses>0 || !addUserValues?.email?.value || !addUserValues?.email?.value?.trim()?.length) return;
    // setInfo({visible:true,message:<p className="t7 reggular-font">By inviting, you agree to purchase <span className="medium-font">1 {reduxSubscription?.plan?.name?.split('-')[0]} plan</span> license for this user.</p>})
  }
  function handleAddNewUserChange(e) {
    const fieldType = e.target.dataset.addtype || e.target.name;
    const value = e.target.value;
    setAddUserValues((prevState) => {
      if (fieldType === "email") {
        // TODO: add email validation
        return { ...prevState, email: { ...prevState.email, value: value } };
      } else if (fieldType === "virtualnumber") return prevState;
    });
  }

  function handleEditUserChange(e, fieldType, value) {
    const _fieldType = fieldType ?? e.target.dataset.edittype;
    let _value = value ?? e.target.value;

    if (_fieldType === "license") {
      setEditUserValues((prev) => ({ ...prev, [_fieldType]: _value }));
      setUpdatePayload((prev) => ({
        ...prev,
        [_fieldType]: _value === "Viewer" ? _value : "Plan",
      }));
    } else {
      setEditUserValues((prev) => ({ ...prev, [_fieldType]: _value }));
      setUpdatePayload((prev) => ({ ...prev, [_fieldType]: _value }));
    }
  }

  function handleCloseDeleteModal() {
    setDeleteModalVisible(false);
  }

  function handleCloseDeactivateModal() {
    setDeactivateModalVisible(false);
  }

  function handleCloseReactivateModal() {
    setReactivateUserModalVisible(false);
  }

  function handleEditModalClose() {
    setEditModalVisible(false);
    setEditUserValues({
      first_name: "",
      last_name: "",
      email: "",
      virtualNumber: [],
      roles: [],
      license: "",
      addonLicenses: [],
    });
    setAddonsToDisplay([...addonLicenseOptions]);
    setUpdatePayload(null);
  }

  function handleAddModalClose() {
    setAddNewUserModalVisible(false);
    setAddUserValues({
      email: { value: "", error: null },
      virtualNumber: "",
      roles: [],
      addonLicenses: [],
      licenseType: "",
    });
  }

  const handleAddNewUserSave = (e) => {
    let isValid = true;
    let errorMessage = "";

    if (addUserValues?.email?.value === "") {
      isValid = false;
      errorMessage = "Email cannot be empty";
    } else if (!validateEmail(addUserValues?.email?.value)) {
      isValid = false;
      errorMessage = "Email must be valid";
    }

    if (!isValid) {
      setAddUserValues((prev) => ({
        ...prev,
        email: { value: prev?.email?.value, error: errorMessage },
      }));
      return;
    }
    if (reduxSubscription?.status === "TRIAL") {
      handleAddUser();
      return;
    }
    if (
      reduxSubscription?.plan_licenses?.free > 0 ||
      reduxSubscription?.viewer_licenses?.free > 0
    ) {
      handleAddUser();
    } else return;
  };

  const handleAddUser = () => {
    if (!showLoader) setShowLoader(true);
    let payload = {
      email: addUserValues?.email?.value,
      virtual_number_id: addUserValues?.virtualNumber?.id,
    };
    if (canManageRoles) {
      if (!addUserValues?.roles?.length) {
        enqueueSnackbar("Roles is a required field", { variant: "error" });
        return;
      }
      payload["roles"] = addUserValues.roles.map((role) =>
        parseInt(role.split(",")?.[1], 10)
      );
    }
    if (addUserValues.addonLicenses?.length) {
      payload["addon_licenses"] = addUserValues.addonLicenses.map((license) =>
        license.toUpperCase()
      );
    }
    if (reduxSubscription?.status === "TRIAL") {
      payload["license"] = "Plan";
    } else {
      payload["license"] = addUserValues?.licenseType?.includes("plan")
        ? "Plan"
        : "Viewer";
    }

    JService.post(
      API_BASE_URL + `/api/v1/auth/add-users/${getOrgURLParam()}`,
      payload
    )
      .then((res) => {
        console.log("response", res);
        if (!res.success) {
          if (res?.details?.email) {
            setAddUserValues((prev) => ({
              ...prev,
              email: { ...prev.email, error: res?.details?.email },
            }));
            return;
          }
          throw new Error(
            res?.message || "Could not add this user, please try again"
          );
        }
        fetchSubscriptionData();
        enqueueSnackbar(res?.message || "Added user", { variant: "success" });
        handleAddModalClose();
        // setVideoModalVisible(true);
        setController((prevState) => ++prevState);
        //}
      })
      .catch((err) => {
        console.log("error", err);
        enqueueSnackbar(err?.message, { variant: "error" });
      })
      .finally(() => setShowLoader(false));
  };

  const handleDeleteUser = (e) => {
    if (!showLoader) setShowLoader(true);
    // added accounts based add-user
    JService.delete(
      API_BASE_URL +
        `/api/v1/auth/delete-user/${getOrgURLParam()}?id=${user?.id}`
    )
      .then((response) => {
        if (!response?.success)
          throw new Error(response?.message || "Could not delete user");
        enqueueSnackbar(response?.message || "Deleted user", {
          variant: "success",
        });
        fetchSubscriptionData();
        setController((prevState) => ++prevState);
        handleCloseDeleteModal();
      })
      .catch((error) => enqueueSnackbar(error?.response || error?.message, { variant: "error"}))
      .finally(() => setShowLoader(false));
  };

  function handleEditUserSave(e, payload) {
    if (!showLoader) setShowLoader(true);

    let _payload = payload || {
      user_id: user?.id,
      ...updatePayload,
    };
    if (canManageRoles) {
      _payload["roles"] = editUserValues.roles.map((role) =>
        parseInt(role.split(",")?.[1], 10)
      );
    } else {
      if ("roles" in _payload) delete _payload["roles"];
    }
    if (canManageNumbers) {
      _payload["outbound_virtual_number_ids"] =
        editUserValues?.virtualNumbers?.map((vn) =>
          parseInt(vn.split(",")?.[1], 10)
        );
    }
    if (editUserValues?.virtualNumber) {
      _payload["virtual_number"] = editUserValues?.virtualNumber?.number;
    }
    if (editUserValues?.addonLicenses) {
      _payload["addon_licenses"] = editUserValues.addonLicenses.map((license) =>
        license.toUpperCase()
      );
    }

    // added accounts based add-user
    JService.patch(
      API_BASE_URL + `/api/v1/auth/update-account-profile/${getOrgURLParam()}`,
      _payload
    )
      .then((response) => {
        if (!response?.success)
          throw new Error(response?.message || "Could not update user details");
        enqueueSnackbar(response?.message || "Updated account profile", {
          variant: "success",
        });
        setController((prevState) => ++prevState);
        if (
          "license" in _payload ||
          "is_active" in _payload ||
          "addon_licenses" in _payload
        ) {
          fetchSubscriptionData();
        }
        handleEditModalClose();
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error" }))
      .finally(() => {
        setShowLoader(false);
        if (deactivateModalVisible) handleCloseDeactivateModal();
        if (reactivateUserModalVisible) handleCloseReactivateModal();
      });
  }

  // page loading-anim setter
  const prevReduxOrg = useRef(reduxOrg?.selectedOrg);
  useEffect(() => {
    if (
      prevReduxOrg.current !== reduxOrg?.selectedOrg &&
      Boolean(reduxOrg?.selectedOrg)
    ) {
      setShowLoader(true);
      // prevReduxOrg.current = ''
      prevReduxOrg.current =
        reduxAccount?.allOrgs?.find((ao) => ao?.primary_account)?.id || "";
      setRows([]);
      // reduxOrg.selectedOrg
      setController((prevState) => ++prevState);
    }
  }, [reduxOrg]); // eslint-disable-line

  useEffect(() => {
    const controller = new AbortController();
    if (!canManageNumbers) return;
    JService.get(
      "/api/v1/auth/virtual-numbers/",
      {},
      { signal: controller.signal }
    )
      .then((response) => {
        if (!("success" in response)) return;
        if (!response?.success)
          throw new Error(
            response?.message || "Could not retreive virtual numbers"
          );
        setVirtualNumbersAvailability(response?.data);
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error" }))
      .finally(() => setShowLoader(false));

    return () => controller.abort();
  }, []); // eslint-disable-line

  // all /org_id dependent fetches go in here
  useEffect(() => {
    const controller1 = new AbortController();
    const controller2 = new AbortController();

    if (!showLoader) setShowLoader(true);

    JService.get(
      API_BASE_URL + `/api/v1/auth/retrieve-users/${getOrgURLParam()}`,
      {},
      { signal: controller1.signal }
    )
      .then((res) => {
        if (!("success" in res)) return;
        if (!res?.success)
          throw new Error(res?.message || "Could not retreive users");
        setData(res?.data);
        let temp = generateUsersTableRowsData(res?.data);
        setMasterRows(temp);

        //TODO:  sort rows according to starting date
        setRows(temp);
      })
      .catch((err) =>
        enqueueSnackbar(err?.message || "Failed to retreive users", {
          variant: "error",
        })
      )
      .finally(() => setShowLoader(false));

    const setRoles = (roles) => {
      dispatchAvailableData({
        type: "setPayloadToAllOfType",
        payload: {
          payload: roles?.map(({ id, title }) => ({ id, name: title })),
          payloadFor: "roles",
        },
      });
    };
    if (canManageRoles) {
      fetchData("/api/v1/auth/roles/", setRoles, "roles");
    }

    setAddonLicenses(reduxSubscription?.addon_licenses || []);

    return () => {
      controller1.abort();
      controller2.abort();
    }
  }, [controller, reduxAccount, canManageRoles]); // eslint-disable-line

  const cols = useMemo(() => {
   return (
   [
      {
        field: "userUser",
        headerName: "User",
        headerClassName: "user-table-header-user-name",
        flex: 0.24,
        renderCell: (params) => {
          return (
            <Box
              sx={{display: 'flex', paddingLeft: "14px", gap: '12px'}}
            >
              <CustomAvatar 
                name={params?.row?.userUser?.firstName || params?.row?.userUser?.email}
              />
              <Box sx={localStyles.flexColumn}>
                <span className="t7 regular-font nc-gray-900 spanNoOverflow">
                  {params?.row?.userUser?.firstName +
                    " " +
                    params?.row?.userUser?.lastName}
                </span>
                <span className="t7 regular-font nc-gray-600 spanNoOverflow">
                  {params?.row?.userUser?.email}
                </span>
              </Box>
            </Box>
          );
        },
      },
      {
        field: "userStatus",
        headerName: "Status",
        headerClassName: "autodial-view-screen-table-header-contacted",
        flex: 0.18,
        renderCell: (params) => {
          const userStatusLower = params?.row?.userStatus?.toLowerCase();
          return (
            <Box
              onClick={(e) =>
                handleUserStatusClick(
                  e,
                  params?.row?.userStatus?.toLowerCase(),
                  params?.id
                )
              }
            >
              <span
                className="t7 regular-font nc-gray-900"
                style={{
                  color: USERSTATUSCOLORS(
                    params?.row?.userStatus?.toLowerCase()
                  ),
                  textDecoration:
                    params?.row?.userStatus?.toLowerCase() ===
                    "resend invitation"
                      ? "underline"
                      : "none",
                }}
              >
                {userStatusLower?.includes("invit")
                  ? userStatusLower === "resend invitation"
                    ? "Resend Invite"
                    : userStatusLower === "invitation sent"
                    ? "Invite Sent"
                    : params?.row?.userStatus
                  : params?.row?.userStatus}
              </span>
            </Box>
          );
        },
      },
      {
        field: "userRole",
        headerName: "Role",
        headerClassName: "autodial-view-screen-table-header-contacted",
        flex: 0.21,
        renderCell: (params) => (
          <Box sx={localStyles.noOverflow}>
            <span className="t7 regular-font nc-gray-900">
              {params?.row?.userRole?.map(role => role.title)?.join(', ')}
            </span>
          </Box>
        ),
      },
      {
        field:'userLicense',
        headerName:"License(s)",
        headerClassName:"autodial-view-screen-table-header-contacted",
        flex: 0.28,
        renderCell:(params)=>(
          <Box sx={localStyles.noOverflow}
          >
            <span className="t7 regular-font nc-gray-900" style={{fontSize:'14px'}}>
            {params?.row?.license?.includes('Plan')? `${reduxSubscription?.plan?.name?.split('-')?.[0]} plan`:`${params?.row?.license ?? ""}`}
            {Array.isArray(params?.row?.userLicense) && params?.row?.userLicense?.length > 0 && params?.row?.license ? ', ':''}
            {Array.isArray(params?.row?.userLicense) &&
            params?.row?.userLicense?.length > 0
              ? `${params?.row?.userLicense?.join(', ')}`
              : ""}
              </span>
          </Box>
        )
      },
      {
        field: "userTeam",
        headerName: "Teams",
        headerClassName: "users-table-header-teams",
        flex: 0.2,
        renderCell: (params) => {
          const teamNames = params.row.userTeam?.map(team => team.name).join(", ") || " ";
          return (
            <CustomTooltip
            tooltipTitle={teamNames}
            tooltipText={teamNames}/>
          );
        },
      },
      
      {
        field: "userEdit",
        headerName: "",
        flex: 0.08,
        cellClassName: "table-cell-edit", // @important add this field to your threedots component
        renderCell: (params) => {
          return (
            <Box
              data-username={
                params?.row?.userUser?.firstName +
                " " +
                params?.row?.userUser?.lastName
              }
            >
             {(hasPermission(null, 'create and edit users', params?.id) 
                || hasPermission(null, 'delete users', params?.id)) ? (
                <img
                  className={"threedots-" + params?.id}
                  data-userid={params?.id}
                  data-username={
                    params?.row?.userUser?.firstName +
                    " " +
                    params?.row?.userUser?.lastName
                  }
                  onClick={(e) => handleThreeDotsClick(e)}
                  src={userEditIcon}
                  alt="three dots icon"
              />) : null}
            </Box>
          );
        },
      },
    ]);
  }, [userRoles, rows, masterRows, reduxSubscription])

  

  // three dots handlers
  const handleThreeDotsClick = (e) => {
    // e.stopImmediatePropagation() //🙊 still propagating to other event listeners and the cell is getting selected
    const username = e.target.dataset.username;
    const userId = e.target.dataset.userid;
    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
    setUsersName(username); // needed for delete
    setUser(RETURN_DATA?.find((rd) => rd?.id == userId)); // eslint-disable-line
  };

  const editValueRef = useRef(null);

  // onclick of one of threedots options
  const handleDropdownClick = (e) => {
    const menuitemtype = e.target.dataset.menuitemtype;
    if (menuitemtype === "edit") {
      let item = data?.find((d) => user?.id == d?.id); // eslint-disable-line
      // item = Array.isArray(item) && item.length > 0 ? item[0] : null
      let iniTeams = item?.teams?.map((t) => t?.name + "," + t?.id);
      // let vn = Array.isArray(item?.virtual_numbers) && item?.virtual_numbers?.length > 0 ? item?.virtual_numbers : []
      // vn?.sort((a, b) => a?.location?.length > b?.location?.length ? -1 : a?.location?.length === b?.location?.length ? 0 : 1)
      // if(vn?.length) vn = vn[0]?.location
      // else vn = ''
      let payload = {
        first_name: item?.first_name,
        last_name: item?.last_name,
        email: item?.email,
        phoneNumber:
          (item?.number?.country_code || "") + item?.number?.phone_number,
        // virtualNumber: vn,
        id: user?.id,
        team: iniTeams || [], // ⏳enable
        roles: item?.roles?.map((role) => `${role.title},${role.id}`),
        virtualNumbers: item?.outbound_virtual_numbers?.map(
          (vn) => `${vn.name || vn.country_code + " " + vn.number},${vn.id}`
        ),
        status: { isActive: item?.is_active, status: item?.status },
        license:
          item?.license === "Plan"
            ? `${reduxSubscription?.plan?.name?.split("-")[0]} plan`
            : item?.license,
        addonLicenses: item?.addons,
      };
      setEditUserValues(payload);
      editValueRef.current = payload;
      setEditModalVisible(true);
      if (deleteModalVisible) setDeleteModalVisible(false);
      if (Array.isArray(item) && item.length > 0) setUser(item[0]);
    } else if (menuitemtype === "delete") {
      setDeleteModalVisible(true);
      if (editModalVisible) setEditModalVisible(false);
    } else if (menuitemtype === "deactivate") {
      setDeactivateModalVisible(true);
    } else if (menuitemtype === "reactivate") {
      if (reduxSubscription?.status === "TRIAL") {
        handleReactivateUser(null, "Plan");
        return;
      }
      setReactivateUserModalVisible(true);
    }

    // closing the dropdown
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null); // @check
  };

  // resend invite from Settings.js/Users.js
  const handleUserStatusClick = (e, status, id) => {
    if (status !== "resend invitation") return;
    const payload = {
      user_id: id,
    };
    JService.post(API_BASE_URL + `/api/v1/auth/send-invite/`, payload)
      .then((response) => {
        if (!response?.success)
          throw new Error(
            response?.message || "Could not resend invite to the user. "
          );
        enqueueSnackbar(response?.message || "Invitation sent to user", {
          variant: "success",
        });
        setController((prevState) => ++prevState);
        fetchSubscriptionData();
      })
      .catch((error) =>
        enqueueSnackbar(
          error?.message || error?.response || "Failed to send invite ",
          { variant: "error" }
        )
      );
  };

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    // clicked outside
    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      return;
    }
  }

  // onScroll to close the threedots dropdown
  const threeDotsCloseOnScroll = (e) => {
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null);

    // remove this scroll listener
    window.removeEventListener("scroll", threeDotsCloseOnScroll);
  };

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    // if(isDropdownVisible)window.addEventListener('scroll', threeDotsDropdownPositionHandler)
    // else window.removeEventListener('scroll', threeDotsDropdownPositionHandler)

    if (isDropdownVisible)
      window.addEventListener("scroll", threeDotsCloseOnScroll);
    else window.removeEventListener("scroll", threeDotsCloseOnScroll);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
      window.removeEventListener("scroll", threeDotsCloseOnScroll);
      // window.removeEventListener('scroll', threeDotsDropdownPositionHandler)
    };
  }, [isDropdownVisible]); // eslint-disable-line

  const csvDownloadBody = null;
  const onSearch = (searchText) => {
    const searchTextLower = searchText.toLowerCase();
    const temp = masterRows?.filter(
      (row) =>
        (row.userUser.firstName + " " + row.userUser.lastName)
          .toLowerCase()
          .includes(searchTextLower) ||
        row.userUser.email.toLowerCase().includes(searchTextLower)
    );
    combineSearchAndFilters(temp);
  };

  function combineSearchAndFilters(arg1) {
    if (
      !availableData?.licenses?.selected?.length &&
      !availableData?.roles?.selected?.length &&
      !availableData?.statuses?.selected?.length
    ) {
      if (arg1 != rows) {
        setRows(arg1); //eslint-disable-line
      }
      return;
    }
    let temp = arg1;
    // ⏳enable
    if (availableData?.licenses?.selected?.length) {
      temp = arg1?.filter((mr) => {
        const licenses = [...mr?.userLicense, mr?.license];
        return (
          licenses?.findIndex((t) =>
            availableData?.licenses?.selected?.includes(t)
          ) !== -1
        );
      });
    }
    // --enable end

    // roles selected changed
    if (availableData?.roles?.selected?.length) {
      temp = temp?.filter((te) => {
        return (
          te?.userRole
            ?.map((role) => role?.id)
            ?.findIndex((id) =>
              availableData?.roles?.selected?.includes(id)
            ) !== -1
        );
      });
    }

    // statuses selected changed
    if (availableData?.statuses?.selected?.length) {
      temp = temp?.filter((te) =>
        availableData?.statuses?.selected?.includes(te?.userStatus)
      );
    }

    // @note removing duplicates
    temp = temp?.reduce(function (x, y) {
      if (x.indexOf(y) < 0) x.push(y);
      return x;
    }, []);

    // sort the rows according to contacted date
    setRows(temp);
  }

  return (
    // try to use screen-container, it is set to fit the viewport.
    <div className="">
      {!showLoader &&
        masterRows &&
        Array.isArray(masterRows) &&
        masterRows.length === 0 && (
          <div className="empty-state-container">
            <img src={usersEmptyStateImage} alt="" />
            {canCreateEditUsers && (
              <>
                <p className="t7 medium-font">Invite your team to FreJun</p>
                <Button
                  variant="filled"
                  buttonText="Invite user"
                  disabled={
                    reduxSubscription?.status !== "TRIAL" &&
                    reduxSubscription?.plan_licenses?.free <= 0 &&
                    reduxSubscription?.viewer_licenses?.free <= 0
                  }
                  isLoading={false}
                  hierarchy="green"
                  onClick={handleAddUsersClick}
                />
              </>
            )}
          </div>
        )}
      {rows &&
        !(
          !showLoader &&
          masterRows &&
          Array.isArray(masterRows) &&
          masterRows.length === 0
        ) && (
          <>
            {canManageBillingSubscription && (
              <div className="user-license-info">
                <div>
                  <p className="t7 medium-font nc-gray-900">
                    {reduxSubscription?.plan?.name?.split("-")[0]} plan:{" "}
                    <span className="t7 regular-font nc-gray-900">
                      {reduxSubscription?.plan_licenses?.total} license{" "}
                    </span>
                    <span className="t7 regular-font nc-gray-400">
                      (
                      {reduxSubscription?.plan_licenses?.total -
                        reduxSubscription?.plan_licenses?.free}{" "}
                      assigned)
                    </span>
                  </p>

                  {reduxSubscription?.viewer_licenses?.total > 0 && (
                    <p className="t7 medium-font nc-gray-900">
                      Viewer license:{" "}
                      <span className="t7 regular-font nc-gray-900">
                        {reduxSubscription?.viewer_licenses?.total} license{" "}
                      </span>
                      <span className="t7 regular-font nc-gray-400">
                        (
                        {reduxSubscription?.viewer_licenses?.total -
                          reduxSubscription?.viewer_licenses?.free}{" "}
                        assigned)
                      </span>
                    </p>
                  )}

                  {addonLicenses
                    .slice(0, showAll ? addonLicenses.length : 1)
                    .map((license, i) => (
                      <p className="t7 medium-font nc-gray-900" key={i}>
                        {license.name[0].toUpperCase() +
                          license.name.slice(1).toLowerCase()}
                        :{" "}
                        <span className="t7 regular-font nc-gray-900">
                          {license.licenses.total} license
                        </span>{" "}
                        <span className="t7 regular-font nc-gray-400">
                          ({license.licenses.total - license.licenses.free}{" "}
                          assigned)
                        </span>
                      </p>
                    ))}
                  {addonLicenses.length > 1 && (
                    <button
                      className="view-more-btn t7 medium-font"
                      onClick={() => setShowAll(!showAll)}
                    >
                      {showAll ? "Hide" : `View more`}
                      {showAll ? <ExpandLess /> : <ExpandMore />}
                    </button>
                  )}
                </div>
              </div>
            )}
            <GHeader
              breadcrumbs={false}
              title="User management"
              threeDots={false}
              topHeader={{}}
              topHeaderVisible={false}
              bottomHeader={{
                contentTitle: "",
                buttons: [],
                isCalendar: false,
              }}
            />
            <div className="filter-btn-container">
              <Filters
                filtersList={filtersList}
                dispatchAvailableData={dispatchAvailableData}
                availableData={availableData}
                dateFilter={false}
                fromScreen="users"
              />
              {addUserButton}
            </div>
            <GTable
              leftHeader={{
                filters: [],
              }}
              dispatchAvailableData={dispatchAvailableData}
              availableData={availableData}
              rightHeader={{
                isSearch: true,
                isRefresh: true,
                isDownload: true,
              }}
              download={{
                data: csvData,
                body: csvDownloadBody,
                filename:
                  "frejun-users-" +
                  new Date().toLocaleString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }),
              }}
              rows={rows}
              fromScreen="users"
              columns={cols}
              useMuiTable={true}
              isLoading={showLoader}
              getTableRowClassName={getUsersRowClassName} // to be revised
              additionalProps={{ bgHover: true }}
              onRowClick={() => null}
              onSearch={onSearch}
              combineSearchAndFilters={combineSearchAndFilters}
            />
          </>
        )}

      {/* three dots dropdown menu */}
      <Menu
        open={isDropdownVisible}
        disableScrollLock={true}
        anchorEl={dropdownAnchorEl}
      >
        {user?.userStatus === "Active" &&
          hasPermission(null, "create and edit users", user?.id) && (
            <MenuItem
              data-menuitemtype="edit"
              onClick={(e) => handleDropdownClick(e)}
              sx={{ cursor: "pointer" }}
            >
              Edit user
            </MenuItem>
          )}
        {!user?.is_active &&
          hasPermission(null, "create and edit users", user?.id) && (
            <MenuItem
              data-menuitemtype="reactivate"
              onClick={(e) => handleDropdownClick(e)}
              sx={{ cursor: "pointer" }}
              disabled={
                reduxSubscription?.status !== "TRIAL" &&
                reduxSubscription?.plan_licenses?.free <= 0 &&
                reduxSubscription?.viewer_licenses?.free <= 0
              }
            >
              Reactivate user
            </MenuItem>
          )}

        {user?.is_active && hasPermission(null, "delete users", user?.id) && (
          <MenuItem
            data-menuitemtype="deactivate"
            onClick={(e) => handleDropdownClick(e)}
            sx={{ cursor: "pointer" }}
          >
            Deactivate user
          </MenuItem>
        )}

        {hasPermission(null, 'delete users', user?.id) && user?.id !== reduxAccount?.profile?.user && (
          <MenuItem
            data-menuitemtype="delete"
            onClick={(e) => handleDropdownClick(e)}
            sx={{ cursor: "pointer" }}
          >
            Delete user
          </MenuItem>
        )}
      </Menu>
      {/* tutorial video link modal*/}
      {/* <VideoLinkModal headingStyle={{marginLeft:'36%'}} visible={videoModalVisible} handleClose={()=>setVideoModalVisible(false)} heading="Invite sent!" text={<span>Assigning 1 virtual number to multiple users? 🤔<br/>
Checkout how you can customize incoming call flow for better call distribution.</span>} url=""/> */}
      {/* delete modal */}
      <GModal
        disableCloseButton={showLoader}
        closeModal={handleCloseDeleteModal}
        body={deleteUserModalBody}
        heading={
          <span className="t7 nc-gray-900 medium-font">Delete user</span>
        }
        visible={deleteModalVisible}
        modalStyle={{
          padding: "32px 24px",
          minHeight: "max-content",
          marginBottom: "32px",
        }}
        bodyWrapperStyle={{ paddingRight: "0", width: "max-content" }}
        bottomButtonsStyle={{ borderTop: "none", padding: "0 24px 20px" }}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            styleOverride={btnStyle}
            onClick={handleCloseDeleteModal}
          />,
          <Button
            disabled={false}
            onClick={handleDeleteUser}
            hierarchy="red"
            variant="contained"
            buttonText="Delete"
            styleOverride={btnStyle}
            isLoading={false}
          />,
        ]}
      />

      {/* deactivate modal */}
      <GModal
        disableCloseButton={showLoader}
        closeModal={handleCloseDeactivateModal}
        body={deactivateUserModalBody}
        heading={
          <span className="t7 nc-gray-900 medium-font">Deactivate user</span>
        }
        visible={deactivateModalVisible}
        modalStyle={{
          padding: "32px 24px",
          minHeight: "max-content",
          marginBottom: "32px",
        }}
        bodyWrapperStyle={{ paddingRight: "0", width: "448px" }}
        bottomButtonsStyle={{ borderTop: "none", padding: "0 24px 20px" }}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            styleOverride={btnStyle}
            onClick={handleCloseDeactivateModal}
          />,
          <Button
            disabled={false}
            onClick={handleDeactivateUser}
            hierarchy="red"
            variant="contained"
            buttonText="Deactivate"
            styleOverride={btnStyle}
            isLoading={false}
          />,
        ]}
      />

      {/* edit modal */}
      <GModal
        modalStyle={{ height: "448px", padding: "32px 18px 32px 26px" }}
        bodyWrapperStyle={{ paddingRight: 0 }}
        disableCloseButton={showLoader}
        closeModal={handleEditModalClose}
        body={editUserModalBody}
        heading={<span className="t6 nc-gray-900 medium-font">Edit user</span>}
        visible={editModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleEditModalClose}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Save"
            isLoading={false}
            onClick={handleEditUserSave}
          />,
        ]}
      />

      {/* add new user modal */}
      <GModal
        modalStyle={{ height: "376px", padding: "32px 18px 32px 26px" }}
        bodyWrapperStyle={{ width: "448px", paddingRight: 0 }}
        disableCloseButton={showLoader}
        bottomButtonsStyle={{ borderTop: "none" }}
        closeModal={handleAddModalClose}
        body={addNewUserModalBody}
        heading={
          <span className="t7 nc-gray-900 medium-font">Invite user</span>
        }
        visible={addNewUserModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleAddModalClose}
          />,
          <Button
            disabled={!addUserValues.email}
            hierarchy="green"
            variant="contained"
            buttonText="Invite"
            isLoading={false}
            onClick={handleAddNewUserSave}
          />,
        ]}
      />

      {/*Reactivate user modal*/}
      <GModal
        heading="Reactivate user"
        visible={reactivateUserModalVisible}
        closeModal={() => setReactivateUserModalVisible(false)}
        bottomButtons={[
          <Button
            styleOverride={btnStyle}
            hierarchy="white"
            buttonText="Cancel"
            variant="outlined"
            onClick={handleCancelReactivateUser}
            disabled={false}
          />,
          <Button
            styleOverride={btnStyle}
            hierarchy="green"
            buttonText="Activate"
            onClick={handleReactivateUser}
            disabled={false}
          />,
        ]}
        body={
          <div>
            <form>
              <Select
                value={reactivateUserValues?.licenseType}
                onChange={(e) =>
                  setReactivateUserValues((prevState) => ({
                    ...prevState,
                    licenseType: e.target.value,
                  }))
                }
                sx={selectStyles}
                placeholder="Select license"
                renderValue={(selected) => {
                  if (!selected)
                    return (
                      <p className="regular-font t7 nc-gray-400">
                        Select license
                      </p>
                    );
                  return (
                    <p className="regular-font t7 nc-gray-900">{selected}</p>
                  );
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {licenseOptions.map((elem) => {
                  return (
                    <MenuItem
                      sx={commonStyles.selectMenuItemStyles}
                      value={elem.type}
                      key={elem.type}
                      disabled={elem.count <= 0}
                    >
                      <span>{elem.type}</span>
                      {reactivateUserValues?.licenseType === elem.type && (
                        <span>{topNavAccountSelectedAccountTickIcon()}</span>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </form>
          </div>
        }
        modalStyle={{ width: "430px", minHeight: "auto", padding: "32px" }}
        bottomButtonsStyle={{ borderTop: "none", paddingTop: "8px" }}
        bodyWrapperStyle={{ paddingRight: 0 }}
      />
    </div>
  );
}

const btnStyle = {
  height: "36px",
  fontSize: "14px",
  lineHeight: "14px",
  fontWeight: "400",
  padding: "10px 16px",
};

const selectStyles = {
  alignSelf: "flex-start",
  minWidth: 200,
  width: "100%",
  maxWidth: "100%",
  height: "40px",
  outline: "none",
  borderRadius: "4px",
  padding: 0,
  marginBottom: "24px",
  ...Styles.t7,
  ...Styles.regular_font,
  ...Colors.nc_gray_900,
  "&:before": {
    outline: "none",
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #34CB65",
  },
};

const localStyles = {
  noOverflow: {
    display: "inline-block",
    maxWidth: "calc(100%)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chipContainer: {
    maxWidth: "350px",
    overflowX: "hidden",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    rowGap: "4px",
  },
};
