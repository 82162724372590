// NOTE: default calendar dateRange == 1 day
// TODO: out of range values for call_reason and call_outcome to be fixed
// if either field is null use '' instead.

// inbound ans => 1
// inbound unans => 2
// outbound answered => 3
// outbound unans => 4

// # components
import Button from "../design/Button";
import GModal from "../design/components/GModal";
import GTable from "../design/components/GTable";
import { Filters } from "../dashboard/Filters";

// # lib
import { Autocomplete, Chip, Select, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/system";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import libphonenumber from "google-libphonenumber";

// # css
import colors from "../design/colors";
import styles from "../design/styles";
import "./callLogsStyles.css";


// # assets
import threedotsIcon from "../../assets/gcall-logs-threedots-icon.svg";
import shareIcon from "../../assets/share.svg";
import {
  callIcon,
  messageIcon,
  callLogsTableIncomingArrow,
  callLogsTableOutgoingArrow,
  topNavDialerScreen3NotesEditPencilIcon,
  callLogsTableBlocked,
} from "../../utility-functions/assetLoader";
// # states
import {
  formatDate,
  formatTime,
  getOrgURLParam,
} from "../../utility-functions";
import { JService } from "../api-service/ApiService";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { enqueueSnackbar } from "notistack";
// # tooltop
import {
  CustomChip,
  CustomTooltip,
} from "../design/components/customComponents";
import { filtersReducer } from "../../utility-functions";
import { hasPermission } from "../features/permission/auth";
import { flushSync } from "react-dom";

const localStyles = {
  spanNoOverFlowStyleForLogsContact: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "14px",
    width: "150px",
  },
};

let previousUrl = null;
let previousFilters = null;
const CALL_TYPES_MAPPING = {
  "inbound answered": 1,
  "inbound unanswered": 2,
  "outbound answered": 3,
  "outbound unanswered": 4,
};

const emptyState = {
  users: { all: [], selected: [] },
  teams: { all: [], selected: [], teamUserMapping: {} },
  statusTypes: {
    all: [
      { id: "outbound answered", name: "Outgoing Answered" },
      { id: "outbound unanswered", name: "Outgoing Unanswered" },
      { id: "inbound answered", name: "Incoming Answered" },
      { id: "inbound unanswered", name: "Incoming Unanswered" },
    ],
    selected: [],
  },
  callReasons: { all: [], selected: [] },
  callReasonsFilter: { all: [], selected: [] },
  callOutcomes: { all: [], selected: [] },
  callOutcomesFilter: { all: [], selected: [] },
  tags: { all: [], selected: [] },
  currentSet: "",
  searchText: "",
  searchFields: [],
  persist: false,
};

const filtersList = [
  { name: "Call type", isSearch: false, payloadFor: "statusTypes" },
  {
    name: "Team",
    isSearch: true,
    payloadFor: "teams",
    permission: "view teams",
  },
  {
    name: "Users",
    isSearch: true,
    payloadFor: "users",
    permission: "view users",
  },
  { name: "Tags", isSearch: true, payloadFor: "tags" },
];

// -- init end

// rows for datagrid generated
const generateLogsRowsFromData = (data, users, blockList) => {
  const returnData = Object.values(data)?.reduce((cumulative, key) => {
    let {
      id,
      start,
      end,
      duration,
      call_reason,
      call_outcome,
      call_type,
      candidate_details, // for name and phone number
      // @note shouldn't we have color coding to distinguish candidate from users ?
      call_status,
      autodial_session_id,
      tags, // [{id, name}]
      sharable_url,
      creator_name,
      team,
      creator_number,
      creator,
      cost,
      contact_list_id,
      virtual_number,
    } = key;

    let dur = duration / 1000;
    const creatorId = users.find((user) => user.email === creator)?.id;

    let row = {
      id,
      logsContact: {
        candidateName: candidate_details?.name || "Unknown",
        candidateNumber: candidate_details?.phone_number,
      },
      // start is used as contactedOn
      logsContactedOn: { date: formatDate(start), time: formatTime(start) },
      logsCallReason: call_reason,
      logsCallOutcome: call_outcome,
      logsCallType: call_type,
      logsUser: { creator_name, creator_number, creator, creatorId },
      logsVirtualNumber: virtual_number,
      logsTeams: team?.name,
      logsDuration:
        Math.floor(dur / 60)
          .toString()
          .padStart(2, "0") +
        "m  " +
        (dur - Math.floor(dur / 60) * 60).toFixed(0).padStart(2, "0") +
        "s",
      logsCallStatus: call_status,
      logsTags: tags,
      logsAutodialEventHidden: autodial_session_id || -1,
      sharableURL: sharable_url,
      logsCost: cost,
      contact_list_id,
      // Boolean( [-1, Inf] + 1 ) -> false if index === -1 && true otherwise
      logsIsBlocked: Boolean(blockList.findIndex(({number, country_code:cc}) => (`${cc}${number}`) === candidate_details?.phone_number) + 1),
    };
console.log("row",team?.name)
    cumulative.push(row);
    return cumulative;
  }, []);
  return returnData;
};
const recomputeBlocked = (data, blockList) => {
  const returnData = Object.values(data)?.reduce((cumulative, key) => {
    const {logsContact} = key;
    
    // Boolean( [-1, Inf] + 1 ) -> false if index === -1 && true otherwise
    key.logsIsBlocked = Boolean(blockList.findIndex(({number, country_code:cc}) => (`${cc}${number}`) === logsContact?.candidateNumber) + 1)

    cumulative.push(key);
    return cumulative;
  }, []);
  return returnData;
};

export const parseNumber = (phoneNumber) => {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  let cc = "";
  let number = "";
  try {
    const numberObj = phoneUtil.parseAndKeepRawInput(phoneNumber);
    cc = numberObj.getCountryCodeOrDefault();
    if(cc) cc = `+${cc}`;
    number = numberObj.getNationalNumberOrDefault();
  } catch (err) {
    console.log("Error while parsing phone number", phoneNumber, err);
  }
  finally {
    return {cc, number};
  }
};

const getUsersRowClassName = () => "logs-table-row";

export default function CallLogs({
  displayTitle,
  fetchData,
  users,
  teams,
  allTags,
  tagDetailsSet,
  setTagDetailsSet,
  allTagsRef,
  setMessageHistoryModal,
  handleOpenConversation,
  messagingEnabled,
  initiateCall,
}) {
  const initialState = localStorage.getItem("frejun-temp-logs-filters")
    ? {
        ...JSON.parse(localStorage.getItem("frejun-temp-logs-filters")),
        persist: false,
      }
    : emptyState;

  if (!localStorage.getItem("frejun-temp-logs-filters"))
    localStorage.setItem(
      "frejun-temp-logs-filters",
      JSON.stringify(initialState)
    );

  const navigate = useNavigate();
  const location = useLocation();

  const reduxAccount = useSelector((state) => state?.account?.value);
  const reduxNavbarPaths = useSelector((state) => state?.navbarPaths?.value); // {current, previous, isBadPath}
  const reduxCredits = useSelector((state) => state?.account?.value?.credits);
  const reduxSubscription = useSelector(
    (state) => state?.account?.value?.subscription
  );
  const maskNumbers = useSelector((state) => state?.auth?.maskNumbers);
  const { canSendSms, canMakeCalls, canViewCallRecording, canDownloadCallLogsAndRecording, canBlockCandidates } = useSelector(
    (state) => state?.auth?.permissions
  );

  const [showTable, setShowTable] = useState(true);
  const [controller, setController] = useState(1); // hook up to useEffect and change controller to fetch data
  const [masterRows, setMasterRows] = useState([]); //unfiltered data from generateLogsRowsFromData
  const [rows, setRows] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: undefined, // +1 for every nextUrl fetched; -1 for prevUrl fetched, at start currentPage == 0
    nextUrl: undefined,
    previousUrl: undefined,
  });
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [calendarDisabled, setCaledarDisabled] = useState(false);

  // retrieve-users response snapshot
  //const [users, setUsers] = useState([]);

  // api retrieve-organization response data
  const [orgDetails, setOrgDetails] = useState(null);

  const [allBlocked, setAllBlocked] = useState([]);

  // needed because masterRows does not have
  // all response fields
  const [data, setData] = useState([]);

  // tags dropdown for call-records
  const [isTagsDropdownVisible, setIsTagsDropdownVisible] = useState(false);
  const [tagsDropdownAnchorEl, setTagsDropdownAnchorEl] = useState(null);

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [blockModalVisible, setBlockModalVisible] = useState(false);
  const [calendarStatus, setCalendarStatus] = useState({
    cset: true,
    cread: false,
  });

  //const [tagDetailsSet, setTagDetailsSet] = useState(false);
  const [gTablePageNumber, setGTablePageNumber] = useState(0);

  // check if globalCalendar(redux) is set before setting calendar's initial state // rename to redux<state-name>, i.e. globalCalendar =to> reduxCalendar
  const [calendar, setCalendar] = useState({
    date_start: formatDate(Date.now() - 86400000),
    date_end: formatDate(Date.now()),
    time_start: "00:00:00",
    time_end: "23:59:59",
  });

  function formatDate(date) {
    return `${new Date(date).getFullYear()}-${(
      "0" +
      (new Date(date).getMonth() + 1)
    ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
  }

  const calendarRef = useRef(null);

  // function handleWindowBeforeUnload(e) {
  // 	console.log('availableData: ', availableData)
  // 	dispatchAvailableData({type: "clearAllFilters"})
  // 	let payload = {...availableData, persist: true, calendar}
  // 	console.log('...payload: ', payload)
  // 	localStorage.setItem('frejun-temp-logs-filters', JSON.stringify(payload))
  // 	let _localFilters = localStorage.getItem('frejun-temp-logs-filters')
  // 	console.log('now persisting: ', _localFilters)
  // 	e.preventDefault()
  // 	e.returnValue = ''
  // }

  const [tempReasonOutcomePair, setTempReasonOutcomePair] = useState({
    id: "",
    callReason: "",
    callOutcome: "",
  });


  const [availableData, dispatchAvailableData] = useReducer(
    filtersReducer,
    initialState
  );

  const [editValues, setEditValues] = useState({
    name: "",
    number: "",
  });

  const [shareValues, setShareValues] = useState("");

  const [orgDetailsSet, setOrgDetailsSet] = useState(false);
  const [blockDetailsSet, setBlockDetailsSet] = useState(false);
  const retrieveCallsSuccessfulRef = useRef(false);

  const [refetchCallLogs, setRefetchCallLogs] = useState(false);

  const [rowsTags, setRowsTags] = useState([]);
  const selectedRowTagRef = useRef([]);
  const lastFetchRef = useRef({});

  function handleRemoveFromSelectedTags(rowId, selectedTagId, selectedTags) {
    setShowTable(false);
    let payload = selectedTags
      ?.filter((s) => s?.id != selectedTagId)
      ?.map((sf) => sf?.id);

    JService.patch(
      "/api/v1/core/update-call-record/",
      JSON.stringify({ call_id: rowId, tag_ids: payload })
    )
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Failed to remove tag");
        enqueueSnackbar(res?.message || "Tag removed successfully", {
          variant: "success",
        });
        setRowsTags([]);

        if (!orgDetailsSet) setOrgDetailsSet(true);

        setRows((prev) =>
          prev.map((p) =>
            p.id === rowId
              ? {
                  ...p,
                  logsTags: allTagsRef.current.filter((tag) =>
                    payload.includes(tag.id)
                  ),
                }
              : p
          )
        );

        setCalendarStatus({ cset: true, cread: false });
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }))
      .finally(() => setShowTable(true));
  }

  function handleTagItemClick(e, newTag = [], call_id = -1) {
    setShowTable(false);
    const payload = [...rowsTags, ...newTag];
    JService.patch(
      "/api/v1/core/update-call-record/",
      JSON.stringify({ call_id, tag_ids: payload })
    )
      .then((res) => {
        if (!res?.success) throw new Error(res?.message || "Failed to add tag");

        enqueueSnackbar(res?.message || "Tag added to call record", {
          variant: "success",
        });

        if (!orgDetailsSet) setOrgDetailsSet(true);

        setRows((prev) =>
          prev.map((p) =>
            p.id === call_id
              ? {
                  ...p,
                  logsTags: allTagsRef.current.filter((tag) =>
                    payload.includes(tag.id)
                  ),
                }
              : p
          )
        );
        setCalendarStatus({ cset: true, cread: false });
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }))
      .finally(() => {
        setShowTable(true);
        setRowsTags([]);
      });
  }

  useEffect(() => {
    if (
      reduxNavbarPaths?.current !== "/call-logs" &&
      reduxNavbarPaths?.previous !== "call-logs"
    ) {
      resetFiltersToDefault();
    }
  }, [reduxNavbarPaths]);

  function resetFiltersToDefault() {
    dispatchAvailableData({ type: "clearAllFilters" }); //⏳@enable
    setCalendar({
      date_start: `${
        new Date(new Date().getTime() - 86400 * 1000 * 1).getFullYear() ===
        new Date().getFullYear()
          ? new Date().getFullYear()
          : new Date().getFullYear() - 1
      }-${
        new Date(new Date().getTime() - 86400 * 1000 * 1).getMonth() ===
        new Date().getMonth()
          ? ("0" + (new Date().getMonth() + 1)).slice(-2)
          : new Date().getMonth().toString() == "0"
            ? "12"
            : ("0" + new Date().getMonth()).slice(-2)
      }-${(
        "0" + new Date(new Date().getTime() - 86400 * 1000 * 1).getDate()
      ).slice(-2)}`,
      date_end: `${new Date().getFullYear()}-${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
      time_start: "00:00:00",
      time_end: "23:59:59",
    });
    localStorage.removeItem("frejun-temp-logs-filters");
  }

  // call-logs are retrieved after orgDetails is set (for callReason
  // and callOutcome dropdowns which depends on availableData to be set)
  const fetchCallLogs = (
    type = "fetch",
    _pageSize = pageSize || 10,
    pageNumberArg = 1
  ) => {
    // (hasPermission && set) || (!hasPermission) ~ !(a ^ a)
    if (tagDetailsSet && orgDetailsSet && (!(canBlockCandidates ^ blockDetailsSet))) {
      if (
        type === "fetch" &&
        (!calendarStatus?.cset || calendarStatus?.cread)
      ) {
        return;
      }

      let isRequestSuccessful = false;

      let InteractionHistoryObject = localStorage.getItem(
        "frejun-interaction-history"
      );
      if (InteractionHistoryObject)
        InteractionHistoryObject = JSON.parse(
          localStorage.getItem("frejun-interaction-history")
        );

      let tempFinalDate = null;

      // use interaction history dates if data is set but not read
      if (
        InteractionHistoryObject?.dset &&
        !InteractionHistoryObject?.cread &&
        !InteractionHistoryObject?.qread
      ) {
        let dateItem =
          InteractionHistoryObject?.data?.last_contacted?.contacted_on; //?.split('T')
        // when lastContacted is null
        if (!dateItem) {
          enqueueSnackbar("The contact has not been contacted yet", {
            variant: "error",
          });
        } else {
          let finalDate =
            new Date(dateItem)?.getFullYear() +
            "-" +
            ("0" + (new Date(dateItem)?.getMonth() + 1))?.slice(-2) +
            "-" +
            ("0" + new Date(dateItem).getDate()).slice(-2);

          tempFinalDate = finalDate; // calendar data is going to be used in params

          // now calendar date is read; update interaction-history in localStorage
          localStorage.setItem(
            "frejun-interaction-history",
            JSON.stringify({ ...InteractionHistoryObject, cread: true })
          );
          InteractionHistoryObject = JSON.parse(
            localStorage.getItem("frejun-interaction-history")
          );
          //disable calendar
          setCaledarDisabled(true);
          setCalendar({
            date_start: tempFinalDate,
            date_end: tempFinalDate,
            time_start: "00:00:00",
            time_end: "23:59:59",
          });
        }
      }

      // overrides calendar dates with interaction history dates
      let paramDates = {
        date_start: tempFinalDate || calendar?.date_start,
        date_end: tempFinalDate || calendar?.date_end,
        time_start: tempFinalDate ? "00:00:00" : calendar?.time_start,
        time_end: tempFinalDate ? "23:59:59" : calendar?.time_end,
      };

      // fetching call-records

      let url = `/api/v1/core/retrieve-calls/${getOrgURLParam()}?webapp=true&page=${
        type === "fetch"
          ? 1
          : gTablePageNumber > 0 && type === "filters-changed"
            ? 1
            : pageNumberArg
      }&page_size=${_pageSize}&date_start=${
        paramDates?.date_start
      }&time_start=${paramDates?.time_start}&date_end=${
        paramDates?.date_end
      }&time_end=${paramDates?.time_end}`;
      const _availableDataEmpty = isFiltersEmpty(availableData);
      if (!_availableDataEmpty) {
        // console.log("---filters not empty")

        if (availableData?.users?.selected?.length)
          url += `&users=${JSON.stringify(availableData?.users?.selected)}`;

        if (availableData?.teams?.selected?.length) {
          url += `&teams=${JSON.stringify(availableData?.teams?.selected?.map((at) => Number(at)))}`;
        }

        if (availableData?.tags?.selected?.length)
          url += `&tags=${JSON.stringify(availableData?.tags?.selected)}`;

        if (availableData?.statusTypes?.selected?.length) {
          let temp = null;
          try {
            temp = availableData?.statusTypes?.selected.map(
              (s) => CALL_TYPES_MAPPING[s]
            );
          } catch (er) {
            temp = null;
          }
          if (temp instanceof Array)
            url += `&call_types=${JSON.stringify(temp)}`;
        }

        if (availableData?.searchText?.trim()?.length !== 0) {
          url += `&search=${encodeURIComponent(
            availableData?.searchText?.trim()
          )}`;
        }

        if (type === "filters-changed" && gTablePageNumber > 0) {
          setPageNumber(1);
          setGTablePageNumber(0);
          setPageData({
            currentPage: 1,
            nextUrl: undefined,
            previousUrl: undefined,
          });
          // _cursors.nextUrl = undefined
          // _cursors.previousUrl = undefined
        }
      } else if (
        InteractionHistoryObject?.cread &&
        InteractionHistoryObject?.data?.phone_number
      ) {
        url += `&search=${encodeURIComponent(
          InteractionHistoryObject?.data?.phone_number?.trim()
        )}`;
      }
      //---- url construction end
      if (lastFetchRef.current === url) {
        return;
      }
      retrieveCallsSuccessfulRef.current = false;
      lastFetchRef.current = url;
      console.log("*fetch url", url);
      (async function () {
        try {
          if (type !== "fetch-more") setShowTable(false);
          const _res = await JService.get(url);
          if (!_res?.success)
            throw new Error(_res?.message || "Could not fetch call records");
          setPageData({
            currentPage: ["fetch", "filters-changed"].includes(type)
              ? 1
              : pageNumberArg,
            nextUrl: _res?.data?.next,
            previousUrl: _res?.data?.previous,
            rowCount: _res?.data?.count || 0,
          });
          let responseData = _res?.data?.results || [];
          if (["fetch-more", "fetch-previous", "fetch-next"].includes(type)) {
            setData((prev) => ({ ...prev, ..._res?.data }));
            let temp = generateLogsRowsFromData(responseData || [], users, allBlocked);
            setRows((prev) => [...prev, ...temp]);
            setMasterRows((prev) => [...prev, ...temp]);
          } else {
            setData(responseData);
            let temp = generateLogsRowsFromData(responseData, users, allBlocked);
            setRows(temp);
            setMasterRows(temp);
          }
          isRequestSuccessful = true;
          retrieveCallsSuccessfulRef.current = true;
          if (InteractionHistoryObject?.cread) {
            localStorage.setItem(
              "frejun-interaction-history",
              JSON.stringify({ ...InteractionHistoryObject, qread: true })
            );
          }
          // if (InteractionHistoryObject?.data?.autodial_id) {
          // 	dispatchAvailableData({
          // 		type: "handleSelectedOfType",
          // 		payload: {
          // 			payload: interactionHistoryObject?.data?.autodial_id,
          // 			payloadFor: "autodialLists",
          // 		},
          // 	});
          // }
        } catch (error) {
          enqueueSnackbar(error?.message, { variant: "error" });
        } finally {
          if (isRequestSuccessful) {
            // localStorage.setItem('frejun-temp-logs-filters', JSON.stringify({...(JSON.parse(localStorage.getItem('frejun-temp-logs-filters'))), persist: false }))
            // apply the filters by dispatching
            dispatchAvailableData({ type: "overwriteState" });

            let interactionHistoryObject = null;
            if (localStorage.getItem("frejun-interaction-history"))
              interactionHistoryObject = JSON.parse(
                localStorage.getItem("frejun-interaction-history")
              );

            if (interactionHistoryObject?.qread) {
              // remove interaction history from localStorage
              localStorage.removeItem("frejun-interaction-history");
            }
            setCalendarStatus({ cread: true, cset: true });
          }
          setShowTable(true);
        }
      })();
    }
  };

  const cols = useMemo(() => {
    return [
      {
        field: "logsContact",
        headerClassName: "user-table-header-user-name",
        headerName: "Contact",
        // flex: 1,
        minWidth: 285,
        renderCell: (params) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                paddingLeft: "14px",
                color: colors.nc_gray_900,
                ...styles.regular_font,
                ...styles.t6,
              }}
            >
              <CustomTooltip
                tooltipTitle={params?.row?.logsContact?.candidateName}
                tooltipText={
                  <>
                    <span
                      style={{
                        display: "inline-block",
                        ...localStyles.spanNoOverFlowStyleForLogsContact,
                      }}
                    >
                      {params?.row?.logsContact?.candidateName}
                    </span>
                  </>
                }
              />

              {maskNumbers &&
              params?.row?.logsContact?.candidateName?.trim()?.length ? null : (
                <span
                  className="nc-gray-600 t6"
                  style={{
                    ...localStyles.spanNoOverFlowStyleForLogsContact,
                    userSelect: "all",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.logsContact?.candidateNumber}
                </span>
              )}
            </div>
            {reduxAccount?.data?.license &&
              reduxAccount?.data?.license !== "Viewer" && (
                <div
                  className="table-cell-edit"
                  style={{
                    width: reduxSubscription?.plan?.name?.includes("USD")
                      ? "80px"
                      : "40px",
                  }}
                >
                  <div>
                    {canMakeCalls && (
                      <span
                        className={"contactIcons"}
                        onClick={(e) =>
                          initiateCall(
                            e,
                            params?.row?.logsContact?.candidateNumber,
                            params?.row?.contact_list_id
                          )
                        }
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        {callIcon()}
                      </span>
                    )}
                    {canSendSms && (
                      <span
                        onClick={(e) =>
                          messagingEnabled &&
                          !params?.row?.logsContact?.candidateNumber?.startsWith(
                            "+91"
                          )
                            ? handleOpenConversation(e, params, "call-logs")
                            : null
                        }
                        disabled={
                          !messagingEnabled ||
                          params?.row?.logsContact?.candidateNumber?.startsWith(
                            "+91"
                          )
                        }
                        className={`contactIcons ${!messagingEnabled || params?.row?.logsContact?.candidateNumber?.startsWith("+91") ? "message-disabled" : ""}`}
                      >
                        {messageIcon()}
                      </span>
                    )}
                  </div>
                </div>
              )}
          </Box>
        ),
      },
      {
        field: "logsContactedOn",
        headerClassName: "user-table-header-user-name",
        headerName: "Contacted on",
        // flex: 1.2,
        minWidth: 220,
        renderCell: (params) => {
          const arrowIdentifier = params?.row?.logsCallStatus === "blocked" ? "blocked":
            params?.row?.logsCallType === "outbound"
              ? params?.row?.logsCallStatus === "unanswered"
                ? "arrowOutMiss"
                : "arrowOut"
              : params?.row?.logsCallType === "inbound"
                ? params?.row?.logsCallStatus === "unanswered"
                  ? "arrowInMiss"
                  : "arrowIn"
                : null;
          let arrow = null;

          if (arrowIdentifier === "blocked") arrow = callLogsTableBlocked("#E22D20", 16, 16);
          else if (arrowIdentifier === "arrowIn")
            arrow = callLogsTableIncomingArrow(colors.ic_green_400);
          else if (arrowIdentifier === "arrowInMiss")
            arrow = callLogsTableIncomingArrow(colors.ic_red_400);
          else if (arrowIdentifier === "arrowOut")
            arrow = callLogsTableOutgoingArrow(colors.ic_green_400);
          else if (arrowIdentifier === "arrowOutMiss")
            arrow = callLogsTableOutgoingArrow(colors.ic_red_400);

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                paddingLeft: "14px",
                color: colors.nc_gray_900,
                ...styles.regular_font,
                ...styles.t6,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                {arrowIdentifier === "arrowIn" && (
                  <div
                    style={{
                      transform: "rotate(0deg)",
                      display: "inline-block",
                      marginTop: "8px",
                    }}
                  >
                    {arrow}
                  </div>
                )}
                {arrowIdentifier === "arrowInMiss" && (
                  <div
                    style={{
                      transform: "rotate(0deg)",
                      display: "inline-block",
                    }}
                  >
                    {arrow}
                  </div>
                )}
                {arrowIdentifier === "blocked" && (
                  <div
                    style={{
                      transform: "rotate(0deg)",
                      display: "inline-block",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginTop: "1px"
                    }}
                  >
                    {arrow}
                  </div>
                )}
                {arrowIdentifier !== "arrowIn" &&
                  arrowIdentifier !== "arrowInMiss" &&
                    arrowIdentifier !== "blocked" && (
                    <div
                      style={{
                        transform: "rotate(0deg)",
                        display: "inline-block",
                      }}
                    >
                      {arrow}
                    </div>
                  )}

                <span
                  style={{
                    marginLeft: "12px",
                    display: "inline-block",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.logsContactedOn?.date}
                </span>
              </Box>

              <p
                style={{ paddingLeft: "28px", fontSize: "14px" }}
                className="nc-gray-600 t6"
              >
                &nbsp;{params?.row?.logsContactedOn?.time}
              </p>
            </Box>
          );
        },
      },
      {
        field: "logsUser",
        headerClassName: "user-table-header-user-name",
        headerName: "User",
        minWidth: 220,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                paddingLeft: "12px",
                color: colors.nc_gray_900,
                ...styles.regular_font,
                ...styles.t6,
              }}
            >
              <Box
                sx={{
                  marginBottom: "4px",
                  alignItems: "center",
                  marginLeft: "12px",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  {params?.value?.creator_name}
                </p>
                <p className="nc-gray-600 t6" style={{ fontSize: "14px" }}>
                  {params?.value?.creator || "N/A"}
                </p>
              </Box>
            </Box>
          );
        },
      },
      {
        field: "logsVirtualNumber",
        headerClassName: "user-table-header-user-name",
        headerName: "Virtual number",
        minWidth: 220,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                paddingLeft: "12px",
                color: colors.nc_gray_900,
                ...styles.regular_font,
                ...styles.t6,
              }}
            >
              <Box
                sx={{
                  marginBottom: "4px",
                  alignItems: "center",
                  marginLeft: "12px",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  {params?.value}
                </p>
                
              </Box>
            </Box>
          );
        },
      },
      {
        field: "logsTeams",
        headerClassName: "user-table-header-user-name",
        headerName: "Team",
        minWidth: 220,
        renderCell: (params) => {
          return (
            <p style={{ fontSize: "14px" }}>
                  {params?.value}
                </p>
          );
        },
      },      
      {
        field: "logsDuration",
        headerClassName: "user-table-header-user-name",
        headerName: "Duration",
        // flex: 1.2,
        minWidth: 220,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                paddingLeft: "14px",
                color: colors.nc_gray_900,
                ...styles.regular_font,
                ...styles.t6,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginLeft: "12px",
                    display: "inline-block",
                    fontSize: "14px",
                  }}
                >
                  {params?.value}
                </span>
              </Box>
            </Box>
          );
        },
      },
      {
        field: "logsCost",
        headerName: `Cost (in ${reduxCredits?.currency || reduxAccount?.data?.currency})`,
        minWidth: 220,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                paddingLeft: "14px",
                color: colors.nc_gray_900,
                ...styles.regular_font,
                ...styles.t6,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginLeft: "12px",
                    display: "inline-block",
                    fontSize: "14px",
                  }}
                >
                  {params?.value || "0.00"}
                </span>
              </Box>
            </Box>
          );
        },
      },
      {
        field: "logsRecording",
        headerName: canViewCallRecording ? "Recording" : "Notes",
        minWidth: 200,
        renderCell: (params) => (
          <Box sx={{ padding: "0px" }}>
            <Button
              onClick={() => onRowClick(params?.id)}
              buttonText="View"
              variant="text"
              hierarchy="white"
              icon={null}
              isLoading={false}
              disabled={false}
              styleOverride={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
                transition: "all 0.3s ease-out",
              }}
            />
          </Box>
        ),
      },
      {
        field: "logsCallReason",
        headerClassName: "logs-table-header-call-reason-outcome",
        headerName: "Call reason",
        // flex: 1,
        minWidth: 200,
        renderCell: (params) => {
          return (
            <Select
              id={`select-reason-${params?.id}`}
              disabled={orgDetails?.call_reasons?.length === 0}
              value={params?.row?.logsCallReason}
              displayEmpty
              onChange={(e) =>
                handleReasonOutcomeChange(e, "reason", params?.id)
              }
              renderValue={(selected) => {
                if (orgDetails?.call_reasons?.length === 0)
                  return (
                    <p aria-label="" className="regular-font t6 nc-gray-400">
                      No call reason
                    </p>
                  );
                if (!selected)
                  return (
                    <p aria-label="" className="regular-font t6 nc-gray-400">
                      Select
                    </p>
                  );
                return (
                  <p
                    aria-label={selected}
                    className="regular-font t6 nc-gray-900"
                  >
                    {selected}
                  </p>
                );
              }}
              placeholder="Select"
              autoFocus={false}
              sx={{
                height: "36px",
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: `1px solid ${colors.nc_gray_300}`,
                },
                "&:disabled": {
                  cursor: "not-allowed",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #34CB65",
                  boxShadow: "0px 0px 0px 4px #F0FCF4",
                },
                outline: "none",
                borderRadius: "4px",
                ...styles.t6,
                ...styles.regular_font,
                color: colors.nc_gray_900,
                background: colors.ic_white,
              }}
            >
              {orgDetails?.call_reasons?.map((acr, index) => (
                <MenuItem value={acr} key={index}>
                  {acr}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      {
        field: "logsCalloutcome",
        headerClassName: "logs-table-header-call-reason-outcome",
        headerName: "Call outcome",
        // flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <Select
            id={`select-outcome-${params?.id}`}
            disabled={orgDetails?.call_outcomes?.length === 0}
            value={params?.row?.logsCallOutcome || ""}
            displayEmpty
            onChange={(e) =>
              handleReasonOutcomeChange(e, "outcome", params?.id)
            }
            renderValue={(selected) => {
              if (orgDetails?.call_outcomes?.length === 0)
                return (
                  <p aria-label="" className="regular-font t6 nc-gray-400">
                    No call outcome
                  </p>
                );
              if (!selected)
                return (
                  <p aria-label="" className="regular-font t6 nc-gray-400">
                    Select
                  </p>
                );
              return (
                <p
                  aria-label={selected}
                  className="regular-font t6 nc-gray-900"
                >
                  {selected}
                </p>
              );
            }}
            placeholder="Select"
            autoFocus={false}
            sx={{
              height: "36px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${colors.nc_gray_300}`,
              },
              "& .MuiSelect-nativeInput": {
                cursor: "pointer",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #34CB65",
                boxShadow: "0px 0px 0px 4px #F0FCF4",
              },
              width: "100%",
              background: colors.ic_white,
            }}
          >
            {orgDetails?.call_outcomes?.map((aco, index) => (
              <MenuItem value={aco} key={index}>
                {aco}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        field: "logsTags",
        headerName: "Tags",
        headerClassName: "logs-table-header-call-reason-outcome",
        minWidth: 400,
        renderCell: (params) => {
          const tagIdArray = params?.row?.logsTags?.map((tag) => tag?.id);
          return (
            <Autocomplete
              onChange={(e, value, reason, details) => {
                selectedRowTagRef.current = value;

                if (!value) return;
                handleTagItemClick(
                  e,
                  value?.map((v) => v?.id) || [],
                  params?.row?.id
                );
              }}
              disableClearable
              filterSelectedOptions
              multiple={true}
              options={allTagsRef.current}
              limitTags={2}
              onOpen={(e) =>
                (selectedRowTagRef.current = params?.row?.logsTags)
              }
              defaultValue={params?.row?.logsTags}
              renderTags={(valueArray, getTagsProps, state) => {
                return params?.row?.logsTags?.map((tag, index) =>
                  index > 1 ? (
                    index === 2 ? (
                      "..."
                    ) : null
                  ) : (
                    <CustomChip
                      key={index}
                      label={tag?.name}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleRemoveFromSelectedTags(
                          params?.row?.id,
                          tag?.id,
                          params?.row?.logsTags || []
                        );
                      }}
                    />
                  )
                );
              }}
              // disableCloseOnSelect
              getOptionLabel={(option) => option?.name}
              // dropdown
              renderOption={(props, option, { selected }) => {
                let isSelected = selected || tagIdArray?.includes(option?.id);
                return (
                  <li
                    style={{
                      backgroundColor: isSelected
                        ? colors.ic_green_100
                        : colors.ic_white,
                      pointerEvents: isSelected ? "none" : "all",
                    }}
                    key={option?.id}
                    {...props}
                  >
                    <Chip
                      label={option?.name}
                      onMouseDown={(e) => null}
                      sx={{
                        cursor: "pointer",
                        borderRadius: "4px",
                        border: "1px solid #E1E6E2",
                        marginRight: "8px",
                        height: "26px",
                        background: colors.ic_white,
                        ...styles.b2,
                        ...styles.regular_font,
                        color: colors.nc_gray_900,
                        lineHeight: "auto",
                        pointerEvents: isSelected ? "none" : "all",
                      }}
                    />
                  </li>
                );
              }}
              sx={{
                ...styles.b2,
                ...styles.regular_font,
                color: colors.nc_gray_900,
                width: 500,
                height: "40px",
                "& .MuiFormControl-root": {
                  height: "100%",
                },
                "& .MuiInputBase-root": {
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  maxWidth: "360px",
                  ".Mui-focused:": {
                    border: `1px solid ${colors.ic_green_500}`,
                    boxShadow: "0px 0px 0px 4px #F0FCF4",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: `1px solid ${colors.nc_gray_300}`,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: `1px solid ${colors.nc_gray_300}`,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: `1px solid ${colors.ic_green_300}`,
                  boxShadow: "0px 0px 0px 4px #F0FCF4",
                },
              }}
              renderInput={(_params) => (
                // no tags for call record
                // isUpdatingTags ? <LinearProgress color="success" /> :
                //  <Button isLoading={true} disabled={true} variant='contained' hierarchy='white' buttonText='loading...' styleOverride={{maxWidth: '360px', border: 'none', outline: 'none'}}/> :
                <TextField
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  {..._params}
                  label={""}
                  placeholder={
                    params?.row?.logsTags?.length === 0 ? "+ Add Tags" : ""
                  }
                  maxRows={1}
                  multiline={false}
                  sx={{
                    maxWidth: "360px",
                    color: colors.nc_gray_900,
                    ...styles.b2,
                    ...styles.regular_font,
                  }}
                  // disabled={isUpdatingTags}
                />
              )}
            />
          );
        },
      },
      {
        field: "logsEdit",
        headerName: "",
        // flex: 0.3,
        width: 60,
        cellClassName: "table-cell-edit", // @important add this field to your threedots component
        renderCell: (params) => {
          return (
            <Box data-callid={params?.id}>
              <img
                style={{ cursor: "pointer" }}
                className={"threedots-" + params?.id}
                data-callid={params?.id}
                onClick={(e) => handleThreeDotsClick(e)}
                src={threedotsIcon}
                alt="Three dots icon"
              />
            </Box>
          );
        },
      },
    ];
  }, [
    rows,
    masterRows,
    canSendSms,
    canMakeCalls,
    canViewCallRecording,
    reduxCredits,
    reduxAccount,
    reduxSubscription,
    maskNumbers,
    orgDetails,
  ]);

  useEffect(() => {
    fetchCallLogs("fetch");
  }, [
    orgDetailsSet,
    blockDetailsSet,
    calendarStatus,
    tagDetailsSet,
    refetchCallLogs,
    maskNumbers,
  ]);

  // api-fetch
  useEffect(() => {
    if(canBlockCandidates) {
      // blocked-list fetch
      if(!blockDetailsSet) {
        setAllBlocked([]);
        
        JService.get("/api/v1/auth/blocked-list/")
        .then((res) => {
          if(!res?.success) throw new Error(res?.message || "Could not retrieve Block list");
          let data = [];
          if(!res?.data || !Array.isArray(res.data)) {
            // pass
          }
          else data = res.data;
          if(retrieveCallsSuccessfulRef.current) {
            // re compute block/unblock (logsIsBlocked) shown in threedots dropdown for each row
            const temp = recomputeBlocked(rows, data); // keep filters
            setRows(temp);
            // re compute for masterRows also
            const tempMaster = recomputeBlocked(masterRows, data);
            setMasterRows(tempMaster);
          }
          flushSync(() => {
            setAllBlocked(data);
            setBlockDetailsSet(true);
          });
        })
        .catch(error => enqueueSnackbar(error?.message, {variant:"error"}));
      }
    }
    else {
      if(blockDetailsSet) {
        setBlockDetailsSet(false);
        setAllBlocked([]);
      }
    }

    // orgs
    if(!orgDetailsSet) {
      if (
        reduxAccount?.data?.profile?.account_holder &&
        reduxAccount?.allOrgs?.length > 1
      ) {
        let currentOrgDetails = reduxAccount?.allOrgs?.find(
          (ao) => ao?.id === reduxAccount?.selectedOrg
        );
  
        prevReduxOrg.current = reduxAccount?.selectedOrg;
        setOrgDetails(currentOrgDetails);
        dispatchAvailableData({
          type: "setPayloadToAllOfType",
          payload: {
            payloadFor: "callReasons",
            payload: currentOrgDetails?.call_reasons,
          },
        });
        dispatchAvailableData({
          type: "setPayloadToAllOfType",
          payload: {
            payloadFor: "callOutcomes",
            payload: currentOrgDetails?.call_outcomes,
          },
        });
        setOrgDetailsSet(true);
      } else if (
        !reduxAccount?.data?.profile?.account_holder ||
        (reduxAccount?.data?.profile?.account_holder &&
          reduxAccount?.allOrgs?.length < 2)
      ) {
        JService.get("/api/v1/auth/retrieve-organization/")
          .then((res) => {
            if (!res?.success)
              throw new Error(
                res?.message ||
                  "Could not retreive options for call reason and outcome"
              );
            setOrgDetails(res?.data || {});
            dispatchAvailableData({
              type: "setPayloadToAllOfType",
              payload: {
                payloadFor: "callReasons",
                payload: res?.data?.call_reasons || [],
              },
            });
            dispatchAvailableData({
              type: "setPayloadToAllOfType",
              payload: {
                payloadFor: "callOutcomes",
                payload: res?.data?.call_outcomes || [],
              },
            });
            setOrgDetailsSet(true);
          })
          .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }));
      }
    }
  }, [controller, canBlockCandidates]);

  useEffect(() => {
    if (allTags && Array.isArray(allTags)) {
      setTagDetailsSet(true);
      dispatchAvailableData({
        type: "setPayloadToAllOfType",
        payload: { payloadFor: "tags", payload: allTags },
      });
    }
  }, [allTags]);

  useEffect(() => {
    if (users && Array.isArray(users) && users.length > 0) {
      dispatchAvailableData({
        type: "setPayloadToAllOfType",
        payload: {
          payloadFor: "users",
          payload: users?.map((rd) => ({
            name: rd?.first_name + " " + rd?.last_name,
            id: rd?.id,
          })),
        },
      });
    }
  }, [users]);

  useEffect(() => {
    console.log("teams", teams);

    if (teams && Array.isArray(teams)) {
      const teamUserMapping = {};
      teams?.forEach((u) => {
        teamUserMapping[u.id] = u.users || [];
      });
      dispatchAvailableData({
        type: "setPayloadToAllOfType",
        payload: {
          payloadFor: "teams",
          payload: teams?.map((u) => ({
            name: u.name,
            id: u.id,
            // users: u.users || [],
          })),
          extraPayload: teamUserMapping,
        },
      });
    }
  }, [teams]);

  // rowClick handler
  function onRowClick(rowId) {
    navigate(`/call-recording/${rowId}`);
  }

  // onSearch is called from GTable
  const onSearch = (searchText) => {
    // used for reset when cleared and value of textField is null
    navigate(0);
  };

  useEffect(() => {
    let _localFilters = JSON.parse(
      localStorage.getItem("frejun-temp-logs-filters")
    );
    if (_localFilters) {
      let payload = { ..._localFilters, ...availableData };
      if (
        calendarRef.current &&
        Object.keys(calendarRef.current)?.includes("date_start")
      )
        payload.calendar = calendarRef.current;
      if (!_localFilters?.persist)
        localStorage.setItem(
          "frejun-temp-logs-filters",
          JSON.stringify(payload)
        );
    }

    // NOTE: date is handled separately
    //
    if (isFiltersEmpty(availableData) && isFiltersEmpty(previousFilters)) {
      // reset the rows OR fallback to the previous state, i.e. return(return if paginated response)
      //console.log("----filters have not been set RETURNING")
      return;
    }
    console.log(
      structuredClone(previousFilters),
      structuredClone(availableData)
    );
    // check if filters actually changed;
    if (isFiltersChanged(previousFilters, availableData)) {
      //console.log("-------filters changed")
      fetchCallLogs("filters-changed", pageSize, 1);
    }
    previousFilters = structuredClone(availableData);
  }, [availableData]); //eslint-disable-line

  function isSameArr(ol, ne) {
    // ne have all in ol and same length
    if (!Array.isArray(ol) || !Array.isArray(ne)) return false;
    try {
      return ol.every((o) => ne.includes(o)) && ol.length === ne.length;
    } catch (err) {
      return true;
    }
  }

  // "true" if filtersChanges else "false"
  function isFiltersChanged(oldf, newf) {
    if (oldf === null && isFiltersEmpty(newf)) {
      return false;
    }
    try {
      const isStatusTypesSame = isSameArr(
        oldf?.statusTypes?.selected,
        newf?.statusTypes?.selected
      );
      if (!isStatusTypesSame) {
        return true;
      }

      const isTeamsSame = isSameArr(
        oldf?.teams?.selected?.flatMap((tArr) => tArr),
        newf?.teams?.selected?.flatMap((tArr) => tArr)
      );
      if (!isTeamsSame) {
        return true;
      }

      const isUsersSame = isSameArr(
        oldf?.users?.selected,
        newf?.users?.selected
      );
      if (!isUsersSame) {
        return true;
      }

      const isTagsSame = isSameArr(oldf?.tags?.selected, newf?.tags?.selected);
      if (!isTagsSame) {
        return true;
      }

      if (oldf?.searchText.trim() !== newf?.searchText.trim()) {
        console.log("in here");
        return true;
      }

      return false;
    } catch (error) {
      console.error("error from isFiltersChanged ", error);
    }
  }

  // arg has same structure as asvailableData
  function isFiltersEmpty(arg) {
    return (
      arg?.statusTypes?.selected?.length === 0 &&
      arg?.teams?.selected?.length === 0 &&
      arg?.users?.selected?.length === 0 &&
      arg?.tags?.selected?.length === 0 &&
      arg?.searchText.trim()?.length === 0
    );
  }

  const selectedRowRef = useRef(null); // for edit and shareable-link

  // three-dots dropdown
  function handleThreeDotsClick(e) {
    e.stopPropagation();
    const callid = e.target.dataset.callid;

    // current row call-id
    let currentLog = masterRows?.filter(
      (mrf) => mrf?.id == callid
    );
    selectedRowRef.current = currentLog;

    setIsDropdownVisible(true);
    setDropdownAnchorEl(e.currentTarget || null);
  }

  function handleDropdownClick(e) {
    const menuitemtype = e.target.dataset.menuitemtype;
    let currentLog = selectedRowRef.current;

    if (menuitemtype === "edit") {
      // set edit values and open modal
      if (currentLog?.length === 0) {
		    enqueueSnackbar("Failed to retreive log details", { variant: "error"})
        return;
      }
      currentLog = currentLog?.at(0);
      setEditValues({
        name: currentLog?.logsContact?.candidateName ?? "",
        number: currentLog?.logsContact?.candidateNumber ?? "",
      });
      if (shareModalVisible) setShareModalVisible(false);
      if (blockModalVisible) setBlockModalVisible(false);
        setEditModalVisible(true);
    } else if (menuitemtype === "share") {
      if (currentLog.length !== 0) {
    		if(!currentLog?.at(0).sharableURL) {
			    enqueueSnackbar("No Recordings Found For Unanswered Call", { variant: "error"})
			    return
		    }
        navigator.clipboard.writeText(currentLog?.at(0).sharableURL);
		    enqueueSnackbar("Copied shareable link to clipboard", { variant: "success"})
        
        if (editModalVisible) setEditModalVisible(false);
        if (blockModalVisible) setBlockModalVisible(false);
        setShowTable(true);
      }
    }
    else if(menuitemtype === "block") {
      if (currentLog?.length === 0) {
		    enqueueSnackbar("Failed to retreive log details", { variant: "error"})
        return;
      }
      if(shareModalVisible) setShareModalVisible(false);
      if(editModalVisible) setEditModalVisible(false);
      if(currentLog?.at(0)?.logsIsBlocked) {
        // blockModal NOT to be shown
        setShowTable(false);
        // Api call DEL:/block-request
        handleBlockModalSave();
      }
      else setBlockModalVisible(true);
    }

    // close dropdown menu
    setIsDropdownVisible(false);
    setDropdownAnchorEl(null);
  }

  function threeDotsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      // clicked outside
      setIsDropdownVisible(false);
      setDropdownAnchorEl(null);
      setIsTagsDropdownVisible(false);
      setTagsDropdownAnchorEl(null);
      return;
    }
  }

  function tagsAutoClose(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      // clicked outside
      setIsTagsDropdownVisible(false);
      setTagsDropdownAnchorEl(null);

      return;
    }
  }

  useEffect(() => {
    if (isDropdownVisible) window.addEventListener("click", threeDotsAutoClose);
    else window.removeEventListener("click", threeDotsAutoClose);

    if (isTagsDropdownVisible) window.addEventListener("click", tagsAutoClose);
    else window.removeEventListener("click", tagsAutoClose);

    return () => {
      window.removeEventListener("click", threeDotsAutoClose);
      window.removeEventListener("click", tagsAutoClose);
    };
  }, [isDropdownVisible, isTagsDropdownVisible]);

  // modals
  const shareModalBody = shareModalVisible ? (
    <Box>
      <form
        style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
      >
        <label
          className="t7 nc-gray-900 medium-font"
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
          Recording link {console.log("recording link: ", shareValues)}
          <input
            defaultValue={shareValues}
            type="text"
            className="t6 regular-font nc-gray-900"
            style={{
              marginTop: "4px",
              padding: "8px 12px",
              borderRadius: "4px",
              border: "1px solid #E1E6E2",
            }}
          />
        </label>
      </form>
    </Box>
  ) : null;

  const editModalBody = editModalVisible ? (
    <Box>
      <form
        style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
      >
        {/* name */}
        <label
          className="t7 nc-gray-900 medium-font"
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
          Name
          <input
            value={editValues?.name}
            type="text"
            className="t6 regular-font nc-gray-900"
            style={{
              marginTop: "4px",
              padding: "8px 12px",
              borderRadius: "4px",
              border: "1px solid #E1E6E2",
            }}
            data-edittype="name"
            onChange={(e) =>
              setEditValues((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }
          />
        </label>

        {/* phoneNumber */}
        {maskNumbers ? null : (
          <label
            className="t7 nc-gray-900 medium-font"
            style={{
              marginBottom: "24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "stretch",
            }}
          >
            Phone number
            <input
              value={editValues?.number}
              readOnly={true}
              type="text"
              className="t6 regular-font nc-gray-600"
              style={{
                marginTop: "4px",
                padding: "8px 12px",
                borderRadius: "4px",
                border: "1px solid #E1E6E2",
                backgroundColor: colors.nc_gray_100,
                color: colors.nc_gray_800 + " !important",
              }}
            />
          </label>
        )}
      </form>
    </Box>
  ) : null;

  const blockModalBody = blockModalVisible ? (
    <Box>
      <form
        style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
      >
        <label
          className="t7 nc-gray-900 regular-font"
          style={{
            
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            lineHeight: "25px",
          }}
        >
          <div>
            Are you sure you want to block <span className="medium-font">{(selectedRowRef.current?.at(0)?.logsContact?.candidateName ?? "") === "Unknown" ? "" : selectedRowRef.current?.at(0)?.logsContact?.candidateName} {maskNumbers ? "" : `(${selectedRowRef.current?.at(0)?.logsContact?.candidateNumber})`}</span>? <br/> This will prevent them from calling or messaging you.
          </div>
        </label>
      </form>
    </Box>
  ) : null;

  function handleReasonOutcomeChange(e, type, rowId) {
    const value = e.target.value;

    if (type === "reason") {
      // check if outcome is set or not to decide on snackbar error or success
      const correspondingOutcomeChild = document.querySelector(
        `#select-outcome-${rowId}`
      )?.firstChild;

      let correspondingOutcome = "";
      let isSet = false;

      if (correspondingOutcomeChild)
        correspondingOutcome = correspondingOutcomeChild?.ariaLabel;
      console.log("1.the correspondingOutcome is ", correspondingOutcome);
      // if(availableData?.callOutcomesFilter?.all?.includes(correspondingOutcome)) isSet = true;
      if (correspondingOutcome) isSet = true;
      console.log("availableData ", availableData);

      setTempReasonOutcomePair({
        id: rowId,
        callReason: value,
        callOutcome: correspondingOutcome || "",
      });

      setRows((prevState) =>
        prevState?.map((pvr) =>
          pvr?.id != rowId ? pvr : { ...pvr, logsCallReason: value }
        )
      );

      if (!isSet) {
        enqueueSnackbar("Select a valid value for call outcome as well", {
          variant: "error",
        });
        return;
      }

      JService.patch(
        `/api/v1/core/update-call-record/`,
        JSON.stringify({
          call_id: rowId,
          call_reason: value,
          call_outcome: correspondingOutcome,
        })
      )
        .then((res) => {
          // TODO: check if success in res
          enqueueSnackbar(res?.message || "Updated call record", {
            variant: "success",
          });

          setTempReasonOutcomePair({
            id: "",
            callReason: "",
            callOutcome: "",
          });
        })
        .catch((error) =>
          enqueueSnackbar(error?.message, { variant: "error" })
        );
    } else if (type === "outcome") {
      const correspondingReasonChild = document.querySelector(
        `#select-reason-${rowId}`
      )?.firstChild;
      let correspondingReason = "";
      let isSet = false;

      if (correspondingReasonChild) {
        correspondingReason = correspondingReasonChild?.ariaLabel;
      }

      //   if(availableData?.callReasonsFilter?.all?.includes(correspondingReason)) isSet = true;
      // FIXME:
      if (correspondingReason) isSet = true;
      setTempReasonOutcomePair({
        id: rowId,
        callReason: correspondingReason || "",
        callOutcome: value,
      });

      setRows((prevState) =>
        prevState?.map((pvr) =>
          pvr?.id != rowId ? pvr : { ...pvr, logsCallOutcome: value }
        )
      );

      if (!isSet) {
        enqueueSnackbar("Select a valid value for call reason as well", {
          variant: "error",
        });
        return;
      }

      JService.patch(
        `/api/v1/core/update-call-record/`,
        JSON.stringify({
          call_id: rowId,
          call_reason: correspondingReason,
          call_outcome: value,
        })
      )
        .then((res) => {
          if (!res?.success)
            throw new Error(res?.message || "Failed to update call record");
          enqueueSnackbar(res?.message || "Updated call record", {
            variant: "success",
          });

          setTempReasonOutcomePair({
            id: "",
            callReason: "",
            callOutcome: "",
          });
        })
        .catch((error) =>
          enqueueSnackbar(error?.message, { variant: "error" })
        );
    }
  }

  // @merge-disable
  function handleShareModalSave(e) {
    handleShareModalClose();
    enqueueSnackbar("Copied shareable link to clipboard", {
      variant: "success",
    });
  }

  function handleEditModalSave(e) {
    // update account profile
    if (showTable) setShowTable(false);

    let payload = {
      call_id: selectedRowRef?.current?.at(0)?.id,
      name: editValues?.name,
    };

    JService.patch("/api/v1/core/update-call-record/", JSON.stringify(payload))
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Failed to update call record");
        enqueueSnackbar(
          (res?.message || "Updated call record") +
            ". Other records get will updated shortly",
          { variant: "success" }
        );

        handleEditModalClose();
      })
      .catch((error) => enqueueSnackbar(error?.message, { variant: "error" }));
  }

  async function handleBlockModalSave(e) {
    setShowTable(false);
    try {
		  const url = "/api/v1/auth/blocked-list/";
      const selectedRow = selectedRowRef.current;
      const isBlocked = selectedRow?.at(0)?.logsIsBlocked;
		  
      const candidateNumber = selectedRow?.at(0)?.logsContact?.candidateNumber;
      console.log("candidateNumber=", candidateNumber, "selectedrow", selectedRow);
      const {cc, number} = parseNumber(candidateNumber);
      
      if(!cc) throw new Error("Invalid country code");
      if(!number) throw new Error("Invalid national number");
      
      let response;
      let fallbackSnackSuccessMessage;
      let fallbackSnackFailureMessage;
      if(isBlocked) {  
        const query = new URLSearchParams({cc, number});
        fallbackSnackFailureMessage = "Failed to unblock contact";
        fallbackSnackSuccessMessage = "Successfully unblocked contact";
        response = await JService.delete(url + "?" + query);
      }
      else if(isBlocked === false) {
        const candidateName = selectedRow?.at(0)?.logsContact?.candidateName;  
        if(!candidateName) throw new Error("Contact name missing");
        
        const payload = {};
        payload.number = number;
        payload.country_code = cc;
        payload.name = candidateName;
        fallbackSnackFailureMessage = "Failed to block contact";
        fallbackSnackSuccessMessage = "Successfully blocked contact";
        response = await JService.post(url, payload);
      }
      else throw new Error(`Unknown value for 'is_blocked' (${isBlocked}) for contact. Contact FreJun support.`);
      
		  if(!response?.success) throw new Error(response?.message || fallbackSnackFailureMessage);
		  enqueueSnackbar(response?.message || fallbackSnackSuccessMessage, {variant:"success"});
      // in-place refresh
      setBlockDetailsSet(false);
      setController(prev=> ++prev);
		}
		catch(error) {
		  enqueueSnackbar(error?.message, {variant:"error"});
		}
    finally {
      setShowTable(true);
      handleBlockModalClose();
    }
  }

  function handleShareModalClose() {
    setShareModalVisible(false);
    selectedRowRef.current = null;
  }

  function handleEditModalClose() {
    setEditModalVisible(false);
    setEditValues({
      name: "",
      number: "",
    });
    selectedRowRef.current = null;
    setTimeout(() => {
      setShowTable(true);
      setCalendarStatus({ cset: true, cread: false });
    }, 2000);
  }

  function handleBlockModalClose() {
    setBlockModalVisible(false);
    selectedRowRef.current = null;
    setShowTable(true);
    //NOTE: check why timer is used in share and edit modals.
  }

  // csv-download
  const [csvData, setCSVData] = useState({
    headers: [],
    data: [],
    ids: [],
  });

  const csvDownloadBody = null;
  console.log(reduxAccount?.data?.profile?.role);
  function customDownloadHandler(e) {
    let url = `/api/v1/core/send-call-logs/${getOrgURLParam()}?webapp=true&date_start=${calendar?.date_start}&date_end=${calendar?.date_end}`;

    if (availableData?.users?.selected?.length)
      url += `&users=${JSON.stringify(availableData?.users?.selected)}`;

    if (availableData?.teams?.selected?.length)
      url += `&teams=${JSON.stringify(availableData?.teams?.selected)}`;

    if (availableData?.tags?.selected?.length)
      url += `&tags=${JSON.stringify(availableData?.tags?.selected)}`;

    if (availableData?.statusTypes?.selected?.length) {
      let temp = null;
      try {
        temp = availableData?.statusTypes?.selected.map(
          (s) => CALL_TYPES_MAPPING[s]
        );
      } catch (er) {
        temp = null;
      }
      if (temp instanceof Array) url += `&call_types=${JSON.stringify(temp)}`;
    }

    if (availableData?.searchText?.trim()?.length !== 0) {
      url += `&search=${encodeURIComponent(availableData?.searchText?.trim())}`;
    }

    // @cleanup FIXME: logs more than one month fails with 501
    // if(new Date(calendar.date_end).getTime() - new Date(calendar.date_start).getTime() >= 45 * 86400 * 1000) {
    // 	enqueueSnackbar("Please contact FreJun for call logs of more than 1 month", { variant: "error"})
    // 	return
    // }

    setShowTable(false);
    JService.get(url)
      .then((res) => {
        if (!res?.success)
          throw new Error(
            res?.detail || "Error occurred while sending report to inbox"
          );
        enqueueSnackbar(
          res?.message || "You will receive the call logs report in your inbox",
          { variant: "success" }
        );
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }))
      .finally(() => setShowTable(true));
  }

  const prevReduxOrg = useRef(reduxAccount?.selectedOrg);
  useEffect(() => {
    if (
      prevReduxOrg.current !== reduxAccount?.selectedOrg &&
      (Boolean(reduxAccount?.selectedOrg) || reduxAccount?.selectedOrg === 0)
    ) {
      // reduxAccount changed
      // setorgdetails again if allOrgs?.length > 1 && primaryAccount holder
      prevReduxOrg.current = "";
      if (orgDetailsSet) setOrgDetailsSet(false);
      if (tagDetailsSet) setTagDetailsSet(false);
      // reduxOrg.selectedOrg
      setController((prev) => ++prev);
      fetchData();
      setCalendarStatus({ cset: true, cread: false });
    }
  }, [reduxAccount]);

  const handleFilterApply = (filterName, val) => {
    if (filterName === "Date") {
      setCalendar(val);
      previousFilters = null;
      dispatchAvailableData({ type: "clearAllFilters", payload: "" });
      setGTablePageNumber(0); // can be removed
    } else console.log(filterName, val);
    setRefetchCallLogs((prev) => !prev);
    setCalendarStatus({ cset: true, cread: false });
  };

  function handlePageChange(newPageArg, pageDirectionArg) {
    if (pageDirectionArg && newPageArg > -1) {
      const _type = "fetch".concat(pageDirectionArg);
      fetchCallLogs(_type, pageSize, newPageArg + 1);
      setPageNumber(newPageArg + 1);
    }
  }

  function handlePageSizeChange(val) {
    setPageNumber(1);
    setPageSize(val);
    setGTablePageNumber(0);
    fetchCallLogs("page-size-change", val, 1);
  }

  return (
    <div className="screen-container call-logs-override-screen-container">
      <Filters
        onApply={handleFilterApply}
        fromScreen="call-logs"
        availableData={availableData}
        dispatchAvailableData={dispatchAvailableData}
        filtersList={filtersList}
        calendarDisabled={calendarDisabled}
        date={calendarDisabled ? calendar?.date_start : undefined}
      />
      {showTable ? (
        <GTable
          className="call-logs-gtable"
          leftHeader={{
            isSearch: true,
            filters: [],
          }}
          searchFields={["logsContact"]}
          dispatchAvailableData={dispatchAvailableData}
          availableData={availableData}
          fromScreen="call-logs" // used to render clear all filters
          //refreshHandler={resetFiltersToDefault}
          refreshHandler={null}
          rightHeader={{ isRefresh: true, isDownload: canDownloadCallLogsAndRecording }}
          download={{
            isCustom: true,
            customDownloadHandler,
            data: csvData,
            body: csvDownloadBody,
            filename:
              "frejun-call-logs-" +
              new Date().toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }),
          }}
          rows={rows}
          columns={cols}
          useMuiTable={true}
          isLoading={false}
          getTableRowClassName={getUsersRowClassName} // to be revised
          additionalProps={{ bgHover: true }}
          onRowClick={() => null}
          onSearch={onSearch}
          combineSearchAndFilters={() => null}
          rowCount={pageData.rowCount || 0}
          handlePageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPage={pageSize}
          gTablePageNumber={gTablePageNumber}
          setGTablePageNumber={setGTablePageNumber}
          paginationMode="server"
        />
      ) : (
        <AnimationPage />
      )}
      {/* three dots dropdown menu */}
      <Menu
        sx={{ "& .MuiList-root": { width: "175px" }, "& .MuiPaper-root": {border: "1px solid var(--grey-200)"} }}
        open={isDropdownVisible}
        anchorEl={dropdownAnchorEl}
      >
        <MenuItem
          data-menuitemtype="edit"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          {/* {topNavDialerScreen3NotesEditPencilIcon()} */}
          &nbsp;&nbsp;Edit name
        </MenuItem>

        {hasPermission(
          "calling",
          "call recording",
          selectedRowRef.current?.at(0)?.logsUser?.creatorId
        ) && (
          <MenuItem
            data-menuitemtype="share"
            onClick={handleDropdownClick}
            sx={{ cursor: "pointer" }}
          >
            {/* <img src={shareIcon} alt="Share recording icon" /> */}
            &nbsp;&nbsp;Share recording
          </MenuItem>
        )}
        {canBlockCandidates && <MenuItem
          data-menuitemtype="block"
          onClick={handleDropdownClick}
          sx={{ cursor: "pointer" }}
        >
          {/* {callLogsTableBlocked("#4A4A4A", 18, 18)} */}
          &nbsp;&nbsp;{selectedRowRef.current?.at(0)?.logsIsBlocked ? "Unblock" : "Block"}
        </MenuItem>}

      </Menu>
      {/* edit modal */}
      <GModal
        disableCloseButton={!showTable}
        closeModal={handleEditModalClose}
        body={editModalBody}
        heading={<span className="t6 nc-gray-900 medium-font">Edit name</span>}
        visible={editModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleEditModalClose}
            styleOverride={commonButtonStyles}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Save"
            isLoading={false}
            onClick={handleEditModalSave}
            styleOverride={{
              ...commonButtonStyles,
              border: `1px solid var(--green-200) !important`,
            }}
          />,
        ]}
      />
      {/* share modal */}
      <GModal
        disableCloseButton={!showTable}
        closeModal={handleShareModalClose}
        body={shareModalBody}
        heading={
          <span className="t6 nc-gray-900 medium-font">
            Share call recording
          </span>
        }
        visible={shareModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="contained"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleShareModalClose}
          />,
          <Button
            disabled={false}
            hierarchy="green"
            variant="contained"
            buttonText="Copy link"
            isLoading={false}
            onClick={handleShareModalSave}
          />,
        ]}
      />
      {/* block modal */}
      <GModal
        bodyWrapperStyle={{
          overflow: "hidden",
        }}
        modalStyle={{
          minHeight: "unset",
        }}
        disableCloseButton={!showTable}
        closeModal={handleBlockModalClose}
        body={blockModalBody}
        heading={<span className="t6 nc-gray-900 medium-font">Block contact</span>}
        visible={blockModalVisible}
        bottomButtons={[
          <Button
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Cancel"
            isLoading={false}
            onClick={handleBlockModalClose}
            styleOverride={commonButtonStyles}
          />,
          <Button
            disabled={false}
            hierarchy="white"
            variant="outlined"
            buttonText="Block"
            isLoading={false}
            onClick={handleBlockModalSave}
            styleOverride={{
              ...commonButtonStyles,
              color: "#E22D20",
              border: `1px solid #E4E4E7 !important`,
            }}
          />,
        ]}
      />
    </div>
  );
}

const commonButtonStyles = {
  border: `1px solid #E4E4E7 !important`,
  padding: "14px 8px",
  borderRadius: "6px",
  fontSize: "14px",
  fontWeight: "500",
}