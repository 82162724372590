import { useEffect, useMemo, useRef, useState } from "react";
import { JService } from "../api-service/ApiService";
import GModal from "../design/components/GModal";
import { enqueueSnackbar } from "notistack";
import { CustomAvatar } from "../design/components/customComponents";
import Button from "../design/Button";
import "./BlockListModal.css";
import { searchIcon } from "../../utility-functions/assetLoader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import colors from "../design/colors";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { useSelector } from "react-redux";

const Scroll_Toggle_Limit = 4;
const Interval_For_Search_Contact = 1000;

export default function BlockListModal({isVisible, handleClose}) {
	const [allBlocked, setAllBlocked] = useState([]);
	const [filteredBlocked, setFilteredBlocked] = useState([]);
	// search in core/search-contact/
	const [searchContact, setSearchContact] = useState("");
	// data from core/search-contact/
	const [searchContactResults, setSearchContactResults] = useState([]);
	const timerRef = useRef();
	// search results dropdown
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
	const [dropdownWidth, setDropdownWidth] = useState(440);
	// to re-focus back on searchContacts input
	const searchContactInputEle = useRef();
	const maskNumbers = useSelector((state) => state?.auth?.maskNumbers);

	// local search
	const [searchBlocked, setSearchBlocked] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	// fetch all blocked contacts on open
	useEffect(() => {
		if(!isVisible) return

		(async () => {
			await fetchAllBlockedContacts(true);
		})();
	}, [isVisible]);

	const fetchAllBlockedContacts = async (isInitialLoad=false) => {
		setIsLoading(true);
		try {
			const response = await JService.get("/api/v1/auth/blocked-list/");
			if(!response?.success) throw new Error(response?.message || "Could not retrieve Block list");
			setIsLoading(false);
			if(!response?.data || !Array.isArray(response.data) || !response.data.length) return
			if(isInitialLoad) enqueueSnackbar(response?.message||"Successfully retrieved blocked contacts", {variant:"success"});
			setAllBlocked(response.data);
			setFilteredBlocked(response.data);
		}
		catch(error) {
			enqueueSnackbar(error?.message, {variant:"error"});
		}
		finally {
			setIsLoading(false);
		}
	}

	const handleSearchContactChange = ({target}) => {
		const value = target.value;
		if(value !== searchContact) {
			setSearchContact(_ => value);
			
			// clear previous fetch call if timeout scheduled
			if(timerRef.current || timerRef.current === 0) {
				console.log("clearing search=", searchContact);
				clearTimeout(timerRef.current);
				setIsLoading(false);
			}

			// when cleared|empty no need to fetch
			if(!validateSearchContact(value)) {
				timerRef.current = null;
				setSearchContactResults([]);
				setIsDropdownVisible(false);
				return;
			}
			console.log("scheduling search=", value);
			setIsLoading(true);
			// fetch contacts with updated search query
			timerRef.current = setTimeout(async () => await fetchContactDropdownList(value), Interval_For_Search_Contact);
			// attach and show dropdown 
			setDropdownAnchorEl(target);
			setDropdownWidth(target.offsetWidth);
			setIsDropdownVisible(true);
		}
	}

	const handleFocusSearchContact = (e) => {
		if(searchContact?.length && !isDropdownVisible) {
			// attach and show dropdown
			setDropdownAnchorEl(e.target);
			setIsDropdownVisible(true);
		}
	}
	const fetchContactDropdownList = async (search) => {
		setIsLoading(true);
		try {
			// api doesn't search for country code for ex query: +65
			const queryParam = new URLSearchParams({search: search.replace("+", "").replace(" ", "")});
			const url = `api/v1/core/search-contact/?${queryParam}`;
			const response = await JService.get(url);
			if(!response?.success) throw new Error(response?.message || `Could not retrieve contacts matching ${search}`);
			if(!response?.data || !Array.isArray(response.data)) return
			setSearchContactResults(response.data);
			
			// attempt input re-focus
			attemptFocusBackOnInput();
		}
		catch(error) {
			enqueueSnackbar(error?.message, {variant:"error"});
		}
		finally {
			setIsLoading(false);
		}
	}

	// user should be able to continue typing in input even after dropdown is opened with search results populated
	const attemptFocusBackOnInput = () => (searchContactInputEle.current && searchContactInputEle.current?.focus instanceof Function) && searchContactInputEle.current.focus();

	const handleSearchBlockedChange = ({target:{value}}) => {
		const lowerCased = value.toLowerCase();
		console.log("blocked search changed to", lowerCased);
		setSearchBlocked(_ => value);
		const data = allBlocked.filter(({country_code:cc, name, number}) => (
			(number && (number.toLowerCase().includes(lowerCased) || lowerCased.includes(number.toLowerCase())) || 
			(name && (name.toLowerCase().includes(lowerCased) || lowerCased.includes(name.toLowerCase()))) || 
			(cc && (cc.toLowerCase().includes(lowerCased) || lowerCased.includes(cc.toLowerCase())))
		)));
		setFilteredBlocked(data);
	}

	const handleAddToBlockList = async (name, number, country_code) => {
		setIsLoading(true);
		try {
		  const url = "/api/v1/auth/blocked-list/";
		  const payload = {
			number,
			name,
			country_code,
		  };
		  const response = await JService.post(url,payload);
		  if(!response?.success) throw new Error(response?.message || `Could not add ${name || number} to Block list`);
		  enqueueSnackbar(response?.message || `${name || number} added to Block list` , {variant:"success"});
		  // refresh blocked list
		  await fetchAllBlockedContacts();
		  // close dropdown
		  setIsDropdownVisible(false);
		}
		catch(error) {
		  enqueueSnackbar(error?.message, {variant:"error"});
		}
		finally {
			setIsLoading(false);
		}
	}

	const handleRemoveFromBlockList = async (id) => {
		setIsLoading(true);
		try {
		  const url = `/api/v1/auth/blocked-list/?id=${id}`;
		  const response = await JService.delete(url);
		  if(!response?.success) throw new Error(response?.message || "Could not remove from Block list");
		  enqueueSnackbar(response?.message || "Contact removed from Block list" , {variant:"success"});
		  // refresh blocked list 
		  await fetchAllBlockedContacts();
		}
		catch(error) {
		  enqueueSnackbar(error?.message, {variant:"error"});
		}
		finally {
			setIsLoading(false);
		}
	}

	const handleCancel = () => {
		// reset search fields
		setSearchBlocked("");
		setFilteredBlocked(allBlocked);

		setSearchContact("");
		setSearchContactResults([]);

		handleClose();
	}
	const handleSave = () => {
		setSearchBlocked("");
		setFilteredBlocked(allBlocked);

		handleClose();
	}
	const blockListModalBody = (
		<div id="block-list-modal-body">
			<input ref={searchContactInputEle} className="block-list-modal-body-item border" placeholder="Enter name or number to add" title="Search all contacts by name or national number(excl. country code)." type="search" value={searchContact} onChange={handleSearchContactChange} onClick={handleFocusSearchContact}/>
			<div className="block-list-modal-body-item border main-body">
				<div className="search-contact-container">
					<div className="search-contact">
						{searchIcon()}
						<input placeholder="Search" title="Search in blocked list" type="search" value={searchBlocked} onChange={handleSearchBlockedChange}/>
					</div>

				</div>
				<div className="blocked-contacts-body">
					{filteredBlocked.length ? (
						filteredBlocked?.map(a => <ContactCard id={a?.id} name={a?.name ?? ""} number={maskNumbers ? "" : `${(a?.country_code + " " + a?.number) ?? ""}`} handleRemove={handleRemoveFromBlockList} isLoading={isLoading}/>)
					) : (
						searchBlocked ? <p className="no-search-result t7 nc-gray-700">No blocked contact matching search query found</p>
					  : <p className="no-search-result t7 nc-gray-700">No blocked contact</p>
					)
					}
					
				</div>
			</div>
		</div>
	)
	const scrollStyles = useMemo(() => {
		const resultsSize = searchContactResults.length;
		if(resultsSize < Scroll_Toggle_Limit) return {};

		// customize scrollbar
		const visibility = searchContactResults.length > 4 ? "visible" : "hidden";
		const style = {
			"& .MuiPaper-root": {
				"&::-webkit-scrollbar": {
					visibility,
					width: 8,
					borderRadius: 8,
				},
				"&::-webkit-scrollbar-thumb": {
					borderRadius: 8,
					background: colors.nc_gray_400,
				},
				"&::-webkit-scrollbar-track": {
					background: colors.ic_white,
				},
				"&::-webkit-scrollbar-corner": {
				  display: "none",
				},
				"&::-webkit-scrollbar-button": {
					display: "none",
				},
			}
		}
		return style;
	}, [searchContactResults.length]);
	useEffect(() => {
		if(isDropdownVisible) {
			window.addEventListener("click", dropdownAutoClose);
			// attempt input re-focus
			attemptFocusBackOnInput();
		}
		
		return () => window.removeEventListener("click", dropdownAutoClose);
	}, [isDropdownVisible])
	const dropdownAutoClose = (e) => {
		const path = e.composedPath()?.map((pathItem) => {
			let newPathItem = null;
			newPathItem =
			  pathItem?.tagName?.toLowerCase() +
			  (pathItem?.className ? "." + pathItem?.className : "");
			return newPathItem;
		});

		if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
			setIsDropdownVisible(false);
		}
	}
	return (
		<>
			<GModal
			noScrollbar={true}
			visible={isVisible}
			closeModal={handleCancel} // all search query and results cleared
			heading="Block list"
			bottomButtons={[
				<Button
				disabled={false}
				hierarchy='white'
				variant='outlined'
				buttonText='Close'
				isLoading={false}
				onClick={handleCancel} // all search query and results cleared
				/>
			]}
			body={blockListModalBody}
			modalStyle={{padding:0, marginBottom:0}}
			bodyWrapperStyle={{width:'488px', paddingRight:0}}
			/>

			<Menu
				sx={{ 
					"& .MuiList-root": { 
						width: dropdownWidth,
						maxHeight: 256,
						borderRadius: 6,
						minHeight: 40,
					}, 
					...scrollStyles,
				}}
				open={isDropdownVisible}
				anchorEl={dropdownAnchorEl}
				autoFocus={false}
				disableAutoFocus={true}
			>
				{isLoading ? <AnimationPage style={noSearchResultsStyle}/> : searchContactResults.length === 0 ? noSearchResultElement : (
					searchContactResults?.map(({name, number,loc__country_code: cc,}) => (
					<MenuItem
						onClick={() => handleAddToBlockList(name, number, cc)}
						sx={{ cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "flex-start",}}
						id={name || number}
						title={`Search result for "${searchContact}". Click to block contact`}
						disabled={isLoading}
				  	>
					<span className="t7 regular-font" style={{color: "var(--grey-700)", marginBottom: 2}}>{name ?? ""}</span>
					<span className="t7 regular-font" style={{color: "var(--grey-500)"}}>{maskNumbers ? "" : `${cc} ${number}`}</span>
				  </MenuItem>
				)))}

			</Menu>
		</>
	)
}

const ContactCard = ({id, name, number, handleRemove, isLoading}) => {
	return (
		<div className="block-list-container" id={(number||"").toString() + id} title={`Click on 'x' to unblock ${name||number}`}>
			<div className="block-list-block">
				<CustomAvatar name={name}/>
			</div>

			<div className="info-container block-list-block">
				<span className="info-block">{name}</span>
				<span className="info-block">{number}</span>
			</div>

			<div className="block-list-block">
				<Button
					title="Unblock contact"
					disabled={isLoading}
					icon={{}}
					hierarchy='white'
					variant='icon'
					buttonText='x'
					isLoading={false}
					styleOverride={{
						width: "20px",
						minWidth: "unset",
						padding: "6px 20px",
					}}
					onClick={() => handleRemove(id)}
				/>
			</div>
		</div>
	)
}

const noSearchResultsStyle = {
	height: 40,
};

const noSearchResultElement = <MenuItem disabled={true}>
	<span className="regular-font t7 nc-gray-700">No contact matching input found</span>
</MenuItem>

const validateSearchContact = (value) => (value instanceof String || typeof(value) === 'string') && value.length && value.trim() !== "+"