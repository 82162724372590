import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import WelcomeModal from "./dashboard/WelcomeModal.jsx";
import GModal from "./design/components/GModal.jsx";
import PlatformRules from './platform-rules/PlatformRules.jsx';
import { UseWebAppPc } from "../utility-functions/assetLoader";
import { useSelector } from 'react-redux';

const FirstLogin = () => {
  const location = useLocation();

  const isLoggedIn = useSelector((state) => state?.user?.value?.isLoggedIn);

  const [showPlatformRules, setShowPlatformRules] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [webAppVisible, setWebAppVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const isFirstLogin = () => location.state && ["signup", "invite"].includes(location.state?.from)

  useEffect(() => {
    if(!isLoggedIn) return;
      const hasSeenPlatformRules = localStorage.getItem("hasSeenPlatformRules");
      if (!hasSeenPlatformRules) {
        setShowPlatformRules(true);
      }
  }, [isLoggedIn]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleWebappPc = () => {
    setWebAppVisible(false);
  };

  const handlePlatformRulesClose = () => {
    localStorage.setItem("hasSeenPlatformRules", true);
    setShowWelcomeModal(true);
  };

    const useWebAppModalBody = (
      <div className="useWeb">
        <div className="useweblogo">
          <UseWebAppPc />
        </div>
        <div className="useWebDis">
          To unlock the full potential of our platform and enjoy an optimized
          experience, we recommend using the web app version on your PC.
        </div>
      </div>
    );

    if(!showPlatformRules || !isFirstLogin()) return null;

    return (
        <>
            {showPlatformRules && (
                <PlatformRules handleClose={handlePlatformRulesClose} />
            )}
            {showWelcomeModal && <WelcomeModal
                visible={showWelcomeModal}
                handleClose={() => {
                    setShowWelcomeModal(false);
                    setWebAppVisible(true);
                }}
            />}
            {isMobileView && webAppVisible && (
                <GModal
                    visible={webAppVisible}
                    closeModal={handleWebappPc}
                    body={useWebAppModalBody}
                    bodyWrapperStyle={{ height: "268px", width: "312px" }}
                    heading={""}
                    bottomButtons={[]}
                />
            )} 
        </>
    )     
}

export default FirstLogin;